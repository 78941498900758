import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Popover,
    TextField,
    Slider,
    Typography,
    Box,
} from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'

const AddressSearch = ({ setCoordinates, setDistance }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [address, setAddress] = useState('')
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [maxDistance, setMaxDistance] = useState(25)
    const inputRef = useRef(null)

    const loadGoogleMapsScript = () => {
        return new Promise((resolve, reject) => {
            // Check if Google Maps script is already loaded
            if (window.google && window.google.maps) {
                resolve()
            } else {
                const script = document.createElement('script')
                script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY}&libraries=places`
                script.onload = () => resolve()
                script.onerror = () =>
                    reject(new Error('Failed to load Google Maps'))
                document.body.appendChild(script)
            }
        })
    }

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
        setSelectedPlace(null)
        setAddress('')
        setMaxDistance(25) // Reset maxDistance when opening
    }

    const handleClose = () => {
        setAnchorEl(null)
        setAddress('')
        setSelectedPlace(null)
        setMaxDistance(25) // Reset maxDistance when closing
    }

    const open = Boolean(anchorEl)

    const fetchGeocodeData = async (address) => {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_GEOENCODING_API_KEY}`
        )
        const data = await response.json()

        if (data.status === 'OK') {
            const { lat, lng } = data.results[0].geometry.location
            setCoordinates([lat, lng]) // Update coordinates without checks
        } else {
            console.error(
                'Geocode was not successful for the following reason:',
                data.status
            )
        }
    }

    useEffect(() => {
        const initAutocomplete = async () => {
            await loadGoogleMapsScript()

            if (inputRef.current) {
                const autocomplete = new window.google.maps.places.Autocomplete(
                    inputRef.current
                )

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace()
                    if (place && place.formatted_address) {
                        setAddress(place.formatted_address)
                        setSelectedPlace(place)
                    }
                })
            }
        }

        if (open) {
            initAutocomplete()
        }

        return () => {
            if (inputRef.current && window.google) {
                const autocomplete = new window.google.maps.places.Autocomplete(
                    inputRef.current
                )
                autocomplete.unbindAll()
            }
        }
    }, [open])

    const handleSubmit = () => {
        if (selectedPlace) {
            fetchGeocodeData(selectedPlace.formatted_address)
        }
        // Only set distance if it actually changes
        if (maxDistance !== 25) {
            setDistance(maxDistance)
        }
        handleClose()
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px',
                justifyContent: 'flex-end',
                position: 'relative',
            }}
        >
            <Button
                variant="outlined"
                startIcon={<PlaceIcon />}
                onClick={handleOpen}
                aria-haspopup="true"
                aria-controls={open ? 'address-popover' : undefined}
                style={{
                    marginLeft: '10px',
                    borderRadius: '4px',
                    borderWidth: '1px',
                    borderColor: '#1976d2',
                }}
            >
                Location
            </Button>
            <Popover
                container={document.body}
                id="address-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    border: '2px solid #1976d2',
                    borderRadius: '4px',
                }}
            >
                <Box
                    p={2}
                    sx={{
                        width: '500px',
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        sx={{ marginBottom: '-15px' }}
                    >
                        <Box sx={{ flex: 1, marginRight: '20px' }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ marginBottom: '-5px' }}
                            >
                                Location
                            </Typography>
                            <TextField
                                autoFocus
                                variant="outlined"
                                placeholder="Enter address"
                                inputRef={inputRef}
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                size="small"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                sx={{ marginBottom: '-5px' }}
                            >
                                Distance Range:
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Slider
                                    value={[0, maxDistance]} // Default start at 0
                                    onChange={(e, newValue) => {
                                        setMaxDistance(newValue[1])
                                    }}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks
                                    min={0}
                                    max={25}
                                    style={{ flexGrow: 1, marginRight: '10px' }}
                                />
                                <TextField
                                    type="text"
                                    value={maxDistance}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        if (/^\d*$/.test(value)) {
                                            setMaxDistance(
                                                Math.min(25, Number(value))
                                            )
                                        }
                                    }}
                                    size="small"
                                    style={{
                                        width: '80px',
                                        marginRight: '10px',
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                    }}
                                >
                                    Enter
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Popover>
        </div>
    )
}

export default AddressSearch
