import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Tooltip,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  InputAdornment,
  Button,
  Card,
  CardContent,
  Badge,
} from '@mui/material';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AddIcon from '@mui/icons-material/Add';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import AddonMessagePanel from '../../Messaging/AddonMessagePanel';
import { getBackendUserUrl } from '../../../utils';
import { UserContext } from '../../../contexts/UserContext';



const hardcodedOptions = [
  { name: 'Paesano\'s Italian', priceOffered: 2000 },
  { name: 'Halal Gyros', priceOffered: 3000 },
  { name: 'Punjab Cafe', priceOffered: 9000 }
]; // Your hardcoded options

const AddonsGrid = ({ eventDetails, onTagClick }) => {
  const { user, setUser, hasRole } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [searchResults, setSearchResults] = useState(hardcodedOptions.map(option => option.name));
  const [searchQuery, setSearchQuery] = useState('');
  const [addonDetails, setAddonDetails] = useState(eventDetails?.event.addons || []);
  const [venueAddons, setVenueAddons] = useState(eventDetails?.venue.addons || []);
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentConfirmIndex, setCurrentConfirmIndex] = useState(null);
  const [notificationCount, setNotificationCount] = useState(3);
  const [messagesPanelOpen, setMessagesPanelOpen] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);

  const [sender, setSender] = useState(user);
  const [receiver, setReceiver] = useState(null);

  const [selectedConversation, setSelectedConversation] = useState(null);

  const fetchAddonContact = async (addon) => {
    try {
      // Check if addon has companyId
      if (!addon.company?.companyId) {
        return;
      }

      const companyId = addon.company.companyId;
      const apiUrl = getBackendUserUrl() + `/event/company_contact?company_id=${companyId}`;      
      const response = await axios.get(apiUrl);
      
      if (response.data) {
        console.log("Addon contact information:", response.data);
        return response.data;
      } else {
        throw new Error('No contact information found for the addon');
      }
    } catch (err) {
      console.error("Error fetching addon contact:", err.message);
      if (err.response) {
        console.error("Error response:", err.response.data);
      }
      return null;
    }
  };

  const getMessages = async ({ eventId, senderId, receiverId }) => {
    try {
      const apiUrl = getBackendUserUrl() + `/send-notification/get_messages`;
      console.log("Fetching messages with params:", { eventId, senderId, receiverId });
      
      const response = await axios.get(apiUrl, {
        params: {
          eventId,
          senderId,
          receiverId
        }
      });

      console.log("Raw response:", response);  // Log the full response
      
      if (response.data.messages) {
        console.log("Messages found:", response.data.messages);
        return response.data.messages;
      } else {
        console.warn("Unexpected response format:", response.data);
        return [];
      }
    } catch (err) {
      console.error("Error fetching messages:", err.message);
      return [];
    }
  };

  const handleOpenMessages = async (addon) => {
    try {
      console.log("Opening messages for addon:", addon);
    
      const contactInfo = await fetchAddonContact(addon);
      console.log("Contact info received:", contactInfo);
    
      if (!contactInfo?.user?.userId) {
          console.error("No user account found for this company");
          toast.error("Cannot start conversation: No user account found for this company");
          return;
      }

      const eventId = eventDetails.event.eventId;
      const senderId = user.user_id;
      const receiverId = contactInfo.user.userId;

      // Set receiver first
      setReceiver(contactInfo);

      // Fetch messages and wait for them
      const messages = await getMessages({eventId, senderId, receiverId});
      console.log("Messages received:", messages);

      if (!Array.isArray(messages)) {
        console.error("Invalid messages format received:", messages);
        toast.error("Error loading messages");
        return;
      }

      // Create conversation object
      const newConversation = {
          eventId: eventId,
          participants: [
              { userId: user.user_id, email: user.email },
              { userId: contactInfo.user.userId, email: contactInfo.user.email }
          ],
          messages: messages
      };

      // Set conversation and wait for state update
      await new Promise(resolve => {
        setSelectedConversation(newConversation);
        setTimeout(resolve, 100); // Give React time to update state
      });

      console.log("Conversation set:", newConversation);
      setMessagesPanelOpen(true);

    } catch (error) {
      console.error("Error in handleOpenMessages:", error);
      toast.error("Error opening conversation");
    }
  };

  const handleEditClick = (index) => {
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
    filterSearchResults(searchQuery); // Filter options based on the current query
  };

  const handleCloseSearchMenu = () => {
    setSearchAnchorEl(null);
  };

  const handleSelectOption = (option, index) => {
    if (index !== null && index >= 0 && index < addonDetails.length) {
      const updatedAddons = [...addonDetails];
      updatedAddons[index].name = option;
      updatedAddons[index].price = Math.floor(Math.random() * (9000 - 2000 + 1)) + 2000;
      setAddonDetails(updatedAddons);
      handleCloseSearchMenu();
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
    filterSearchResults(event.target.value); // Update search results as the query changes
  };

  const filterSearchResults = (query) => {
    const names = hardcodedOptions.map(option => option.name);
    if (query) {
      setSearchResults(names.filter(option => option.toLowerCase().includes(query.toLowerCase())));
    } else {
      setSearchResults(names);
    }
  };

  const handleTextFieldChange = (e) => {
    if (editIndex !== null && editIndex >= 0 && editIndex < addonDetails.length) {
      const updatedAddons = [...addonDetails];
      updatedAddons[editIndex].name = e.target.value;
      setAddonDetails(updatedAddons);
    }
  };

  const handleAccept = (index) => {
    setCurrentConfirmIndex(index);
    setIsConfirming(true);
  };

  const handleConfirm = () => {
    const updatedAddons = [...addonDetails];
    updatedAddons[currentConfirmIndex].status = 'pending'; // Change status to pending confirmation
    setAddonDetails(updatedAddons);
    setIsConfirming(false);
  };

  const handleCancel = () => {
    setIsConfirming(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  // Filter out already selected companies
  const getAvailableAddons = () => {
    const selectedCompanyNames = addonDetails.map(addon => addon.company.name);
    return venueAddons.filter(addon => !selectedCompanyNames.includes(addon.companyName));
  };

  const openAddVendorMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addVendors = (selectedAddon) => {
    console.log("Adding vendor:", selectedAddon);
    // Add your logic to add the vendor to addonDetails
    setAddonDetails([...addonDetails, selectedAddon]);
    handleClose();
    console.log("Addon details", addonDetails);
  };

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            display: 'flex',
            gap: 1, // This adds space between the icons
          }}
        >
          <Tooltip title="Edit" arrow>
            <IconButton
              onClick={() => setIsEditing(!isEditing)}
              sx={{ 
                padding: 1,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add" arrow>
            <IconButton
              onClick={openAddVendorMenu}
              sx={{ 
                padding: 1,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="h6" component="h2" gutterBottom>
          Vendors
        </Typography>
        {Array.isArray(addonDetails) && addonDetails.map((addon, index) => (
          <Box key={index} display="flex" alignItems="center" mb={2}>
            {/* Container for the name and price */}
            <Box flexGrow={1} display="flex" flexDirection="column" mr={2}>
              {isEditing && addon.status === false ? (
                <Box display="flex" alignItems="center">
                  <TextField
                    value={addon.comapny.name}
                    onChange={handleTextFieldChange}
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleSearchClick}
                            size="small"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Menu
                    anchorEl={searchAnchorEl}
                    open={Boolean(searchAnchorEl)}
                    onClose={handleCloseSearchMenu}
                    PaperProps={{
                      style: {
                        maxHeight: 200,
                        width: '20ch',
                        overflowY: 'auto',
                      },
                    }}
                  >
                    <TextField
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                      size="small"
                      sx={{ mb: 1, width: '100%' }}
                    />
                    {searchResults.map((option, idx) => (
                      <MenuItem key={idx} onClick={() => handleSelectOption(option, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  sx={{ mb: 0.5, cursor: isEditing ? 'pointer' : 'default' }}
                  onClick={() => isEditing && handleEditClick(index)}
                >
                  {addon.company.name}
                </Typography>
              )}
              {addon.status === 'pending' ? (
                <Typography variant="body2" color="textSecondary">
                  Price pending confirmation: ${addon.price.toLocaleString()}
                </Typography>
              ) : addon.status ? (
                <Typography variant="body2" color="textSecondary">
                  Price confirmed for: ${addon.price.toLocaleString()}
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Price offered: ${addon.price.toLocaleString()}
                </Typography>
              )}
            </Box>

            {/* Conditional rendering based on addon.confirmed */}
            {addon.status === 'pending' ? (
              <Tooltip title="Pending confirmation" arrow>
                <Tooltip title="Open messages" arrow>
                  <IconButton 
                    sx={{ color: 'blue', minWidth: 'auto', p: 1 }}
                    onClick={() => handleOpenMessages(addon)}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>
                <IconButton sx={{ color: '#FFA500', minWidth: 'auto', p: 1 }}>
                  <WarningIcon />
                </IconButton>
              </Tooltip>
            ) : addon.status ? (
              <Box display="flex" alignItems="center">
                <Tooltip title="Open messages" arrow>
                  <IconButton 
                    sx={{ color: 'blue', minWidth: 'auto', p: 1 }}
                    onClick={() => handleOpenMessages(addon)}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Provider confirmed that timings and price work for them" arrow>
                  <CheckCircleIcon sx={{ color: 'green', fontSize: 24, mr: 1 }} />
                </Tooltip>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" ml={2}>
                <Tooltip title="Accept" arrow>
                  <IconButton sx={{ color: 'green', minWidth: 'auto', p: 1 }} onClick={() => handleAccept(index)}>
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Decline" arrow>
                  <IconButton sx={{ color: 'red', minWidth: 'auto', p: 1 }}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Notification" arrow>
                  <IconButton sx={{ color: 'orange', minWidth: 'auto', p: 1 }}>
                    <Badge badgeContent={notificationCount} color="error" max={99}>
                      <NotificationsActiveIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Open messages" arrow>
                  <IconButton 
                    sx={{ color: 'blue', minWidth: 'auto', p: 1 }}
                    onClick={() => handleOpenMessages(addon)}
                  >
                    <MailOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ask for more information" arrow>
                  <IconButton sx={{ color: 'blue', minWidth: 'auto', p: 1 }}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        ))}

        {/* Confirmation Card */}
        {isConfirming && (
          <Box mt={2}>
            <Card sx={{ p: 2 }}>
              <CardContent>
                <Typography variant="body1">
                  Are you sure you want to confirm this addon?
                </Typography>
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Button variant="contained" color="primary" onClick={handleConfirm}>
                    Confirm
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </Paper>
      <AddonMessagePanel 
        open={messagesPanelOpen}
        onClose={() => setMessagesPanelOpen(false)}
        conversation={selectedConversation}
        onTagClick={onTagClick}
        sender={sender}
        receiver={receiver}
        eventId={eventDetails.event.eventId}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {getAvailableAddons().map((addon, index) => (
          <MenuItem 
            key={addon.addonId} 
            onClick={() => addVendors(addon)}
          >
            <Box>
              <Typography variant="body1">{addon.companyName}</Typography>
              <Typography variant="body2" color="textSecondary">
                {addon.addonType} - ${addon.price}
              </Typography>
            </Box>
          </MenuItem>
        ))}
        {getAvailableAddons().length === 0 && (
          <MenuItem disabled>
            <Typography variant="body2" color="textSecondary">
              No more vendors available
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};

export default AddonsGrid;
