import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/EventRent_transparent.png';
import { PaletteMode } from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { jwtDecode } from 'jwt-decode';
import { IconButton, Badge } from '@mui/material';
import HamburgerMenuWithAvatar from 'components/Hamburgermenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { UserContext } from '../../contexts/UserContext';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

function VenueOwnerNavBar() {
  const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  // const [user, setUser] = useState(null);

  const handleUserLogin = (userData) => {
    setUser(userData);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleUserLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      try {
        setUser(jwtDecode(authToken));
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('authToken');
      }
    }
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1200,
          marginBottom: '0',
        }}
      >
        <Link href="/marketplace">
          <img src={logo} style={logoStyle} alt="logo of sitemark" />
        </Link>
        <Box mt={2} display="flex" alignItems="center" justifyContent="flex-start">
          <Box display="flex" alignItems="center">
            <HamburgerMenuWithAvatar
              onUserLogin={handleUserLogin}
              userData={user}
              onUserLogout={handleUserLogout}
            />
          </Box>
        </Box>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 50,
          py: 2,
          margin: 0,
          padding: 0,
          top: 'auto',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  component={Link}
                  href="/venueOwner/addVenue"
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Add Venue
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href="/venueOwner/myEvents"
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Events
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href="/venueOwner/myVenues"
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    My Venues
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={Link}
                  href="/venueOwner/EventRequests"
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Event Requests
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <form className="d-flex ml-auto" style={{ position: 'relative' }}>
              <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-danger" type="submit" style={{ position: 'absolute', right: 0, height: '100%' }}>
                <i className="fas fa-plus" style={{ color: 'white' }}></i>
              </button>
            </form>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                  </Box>
                  <MenuItem
                    component={Link}
                    href="/venueOwner/addVenue" // Changed to absolute path
                  >
                    Add Venue
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/venueOwner/myEvents" // Changed to absolute path
                  >
                    Events
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/venueOwner/myVenues" // Changed to absolute path
                  >
                    Venues
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/venueOwner/EventRequests" // Changed to absolute path
                  >
                    Event Requests
                  </MenuItem>

                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default VenueOwnerNavBar;
