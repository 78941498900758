import React, { useState, useEffect, useContext } from 'react';
import logo from '../../assets/EventRent_transparent.png';

import { Typography, Menu, Box } from '@mui/material';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { jwtDecode } from 'jwt-decode';
import { IconButton, Badge } from '@mui/material';
import HamburgerMenuWithAvatar from 'components/Hamburgermenu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useParams, useHistory } from 'react-router-dom';

import { UserContext } from '../../contexts/UserContext';
import ComingSoonPopup from '../ComingSoon/ComingSoonPopup';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};
// interface AppNavBarProps {
//   mode: PaletteMode;
//   toggleColorMode: () => void;
// }

const StyledTypography = styled(Typography)(({ theme, isSelected }) => ({
  cursor: 'pointer',
  textDecoration: isSelected ? 'underline' : 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  marginRight: theme.spacing(2),
}));

function EventHostNavBar({ cartCount }) {
  const { user, setUser, hasRole } = useContext(UserContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const products =
      JSON.parse(localStorage.getItem('products')) || [];
  const categories =
      [...new Set(products.map((product) => product.category))];

  const filteredProducts = products.filter(
      (product) =>
          product.productName
                 .toLowerCase()
                 .includes(searchTerm.toLowerCase()) &&
         (selectedCategory?product.category === selectedCategory:true)
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const { showComingSoon } = ComingSoonPopup({ 
    message: "Service Provider View, coming soon! 🎉" // optional
  });


  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    // You can add more logic here, such as navigation or state changes
    console.log(`Selected: ${option}`);
  };

  const handleCartButtonClick = () => {
    // We should also be sending data about what all is in the cart
    // and first we should be showing a checkout page with all the details
    window.location.href = "/shoppingcart";
 };

  const handleUserLogin = (userData) => {
    // This function is called by GoogleSignIn to pass user data up to the parent
    setUser(userData);
  };

  const handleUserLogout = () => {
    setUser(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('authToken');
  };

  useEffect(() => {
    // Load user info from local storage on component mount
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        setUser(decodedToken);
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('authToken'); // Clear invalid token
        setUser(null);
      }
    } else {
      setUser(null);
    }
  }, [setUser]);

  const handleServiceProviderButtonClick = () => {
    showComingSoon();
    // setSelectedOption('Service Provider');
    // history.push('/venueOwner/LandingPage');
  };

  // TODO: The hamburger menu should be conditional, we should create a temp variable for user here
  // and pass as it down as a prop to the Hamburger menu which can then set it on successful login. 
  // once that happens we just want to show the avatar, which when clicked should only show the log out
  //  in the drop down. 
  const [selectedOption, setSelectedOption] = useState('Host');

  return (
    <div>
      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Distribute space between items
        px: 2, // Optional: horizontal padding
        width: '100%', // Ensure the Box takes full width
        position: 'fixed', // Optional: Fixed positioning if needed
        top: 0, // Optional: Position at the top of the viewport
        left: 0, // Optional: Align to the left edge of the viewport
        zIndex: 1200, // Optional: Ensure it appears above other content
        marginBottom: '0', // Reduce margin below the Box
      }}
    >
        <Link href="/marketplace">
            <img src={logo} style={logoStyle} alt="logo of sitemark" />
        </Link>
        <Box mt={2} display="flex" alignItems="center" justifyContent="flex-start">
            {/* <Button
                variant="contained"
                color="primary"
                onClick={handleServiceProviderButtonClick}
                sx={{ mr: 2 }} // Margin right to separate it from the HamburgerMenuWithAvatar
            >
                I am a service provider
            </Button> */}
            <StyledTypography 
              variant="body1" 
              onClick={() => handleOptionClick('Host')}
              isSelected={selectedOption === 'Host'}
            >
              Host
            </StyledTypography>
            <Typography variant="body1" sx={{ mr: 1 }}>|</Typography>
            <StyledTypography 
              variant="body1" 
              onClick={() => handleServiceProviderButtonClick()}
              isSelected={selectedOption === 'Service Provider'}
            >
              Service Provider
            </StyledTypography>
            <Typography variant="body1" sx={{ mr: 1 }}>|</Typography>
            <StyledTypography 
              variant="body1" 
              onClick={() => handleOptionClick('Sponsor')}
              isSelected={selectedOption === 'Sponsor'}
            >
              Sponsor
            </StyledTypography>
            <Box display="flex" alignItems="center">
                <HamburgerMenuWithAvatar 
                    onUserLogin={handleUserLogin} 
                    userData={user} 
                    onUserLogout={handleUserLogout} 
                />
                {hasRole(['admin']) && (
                <IconButton edge="end" color="inherit" aria-label="cart" sx={{ ml: 2 }}>
                    <Badge badgeContent={cartCount} color="secondary" onClick={handleCartButtonClick}>
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>
                )}
            </Box>
        </Box>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 50, // Adjust this value to control the vertical spacing
          py: 2,
          margin: 0,
          padding: 0,
          top: 'auto', // Let margin-top control the spacing
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              marginTop: '15px',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  component={Link}
                  href="/marketplace"
                  sx={{ py: '6px', px: '12px' }}
                 >
                  <Typography variant="body2" color="text.primary">
                    Venue
                  </Typography>
                </MenuItem>
                {hasRole(['admin']) && (
                  <MenuItem
                    component={Link}
                    href="/"
                    sx={{ py: '6px', px: '12px' }}
                  >
                    <Typography variant="body2" color="text.primary">
                      Sponsors
                    </Typography>
                  </MenuItem>
                )}
                <MenuItem
                  component={Link}
                  href="/myevents"
                  sx={{ py: '6px', px: '12px' }}
                 >
                  <Typography variant="body2" color="text.primary">
                    My Events
                  </Typography>
                </MenuItem>
                {hasRole(['admin']) && (
                  <MenuItem
                  component={Link}
                  href="/myevents"
                  sx={{ py: '6px', px: '12px' }}
                 >
                  <Typography variant="body2" color="text.primary">
                    Messages
                  </Typography>
                </MenuItem>
                )}
                <MenuItem
                  component={Link}
                  href="/"
                  sx={{ py: '6px', px: '12px' }}
                 >
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <form className="d-flex ml-auto" style={{ position: 'relative' }}>
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-danger" type="submit" style={{ position: 'absolute', right: 0, height: '100%' }}>
                            <i className="fas fa-plus" style={{ color: 'white' }}></i>
                        </button>
                </form>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                  </Box>
                  <MenuItem
                      component={Link}
                      href="/marketplace"
                    >
                        Venues
                  </MenuItem>
                  <MenuItem
                      component={Link}
                      href="/addVenue"
                    >
                        Add Venue
                  </MenuItem>
                  <MenuItem
                      component={Link}
                      href="/"
                    >
                        Sponsor
                  </MenuItem>
                  <MenuItem
                      component={Link}
                      href="/myevents"
                    >
                        MyEvents
                  </MenuItem>
                  <MenuItem
                      component={Link}
                      href="/"
                    >
                        Pricing
                  </MenuItem>
                  <MenuItem
                      component={Link}
                      href="/"
                    >
                        FAQ
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default EventHostNavBar;
