import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from '../components/collaborators.module.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import CardRow from 'components/Collaborators/CardRow';
import CollaboratorModal from 'components/Collaborators/CollaboratorModal';
import { Box, Button, TextField, Typography, Modal, Grid, Paper, Divider, List, ListItem, ListItemText, ListItemIcon, IconButton, Link } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { getBackendUserUrl } from 'utils';

const VenuePage = ({setCartCount, setCartAddonItems, setCartVenueItems}) => {
  const history = useHistory();
  const { id } = useParams();
  const [venue, setVenue] = useState(null);

  const [imageIsOpen, setImageIsOpen] = useState(false);

  const [error, setError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', subtitle: '', description: '', price: 0, usagecount: 0 });
  // State to manage the addons
  const [cartAddons, setCartAddons] = useState([]); 
  const [addons, setAddons] = useState([]);
  const [venueSponsors, setVenueSponsors] = useState([]);

  // References 
  const imageContainerRef = useRef(null);
  const previousCollaboratorsRef = useRef(null);
  const CartRef = useRef(null);

  //TESTING 
  const [expanded, setExpanded] = useState(false);

  //Cart
  const [guestCount, setGuestCount] = useState(1); // Initial guest count
  const [isEditing, setIsEditing] = useState(false);
  const [newGuestCount, setNewGuestCount] = useState(guestCount);


  // Toggle edit mode
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Save the new guest count
  const handleSaveClick = () => {
    setGuestCount(newGuestCount);
    setIsEditing(false);
  };

  // Cancel editing
  const handleCancelClick = () => {
    setNewGuestCount(guestCount);
    setIsEditing(false);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setNewGuestCount(Number(e.target.value));
  };


  useEffect(() => {
    let isMounted = true; // Add this flag

    const fetchVenue = async () => {
      try {
        const apiUrl = getBackendUserUrl() + `/venue/venue/${id}`;
        const response = await axios.get(apiUrl);
        if (isMounted) { // Only update state if the component is still mounted
          const fetchedVenue = response.data;
          setVenue(fetchedVenue);
          setAddons(fetchedVenue.VenueAddOns || []);
          setVenueSponsors(fetchedVenue.VenueSponsors || []);
        }
      } catch (err) {
        if (isMounted) { // Only update state if the component is still mounted
          setError(err.message);
        }
      }
    };

    fetchVenue();

    return () => {
      isMounted = false; // Set the flag to false when the component unmounts
    };
  }, [id]);

  if (error) return <div>Error: {error}</div>;
  if (!venue) return <div>Loading...</div>;

  const isLongDescription = venue.desc.length > 200;
  const displayedDescription = expanded ? venue.desc : `${venue.desc.substring(0, 200)}...`;

  const lastImageUrl = venue.VenueImages[venue.VenueImages.length - 1]?.url || '';

  const handleReadMore = () => {
    setExpanded(!expanded);
  };

  const handleOpenModal = (collaborator) => {
    setModalContent({ 
      collaborator
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);

  // Function to handle deleting an addon
  const handleDeleteAddon = (addonId) => {
    setCartAddons(cartAddons.filter(addon => addon.addonId !== addonId));
    setCartAddonItems(cartAddons.filter(addon => addon.addonId !== addonId));
  };

  const handleQuickAdd = (collaborator) => {
    setCartAddons([...cartAddons, collaborator]);
    setCartAddonItems([...cartAddons, collaborator]);
    toast.success(`${collaborator?.companyName} added to event`);
  };

  const handleAddAddonFromCollaborators = () => {
    // if (!selectedCollaborator) return;
    setCartCount(cartItemCount => cartItemCount+1);
  }

  const handleAddAddonFromModal = (newAddon) => {
    console.log("handleAddAddonFromModal newAddon", newAddon);
    
    // Preserve original structure and just add/update specific fields
    const formattedAddon = {
      ...newAddon,
      status: 'pending',
      selected: true
    };

    // Use current cartAddons directly instead of relying on prev state
    const updatedAddons = [...cartAddons, formattedAddon];
    setCartAddons(updatedAddons);
    setCartAddonItems(updatedAddons);
    // setCartCount(cartCount + 1);
    
    console.log("Updated cart addons:", updatedAddons);
    handleCloseModal();
  };

  const scrollToCollaborators = () => {
    if (previousCollaboratorsRef.current) {
      previousCollaboratorsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToCart = () => {
    if (CartRef.current) {
      CartRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const addVenueToCart = async () => {
    try {
      const apiUrl = getBackendUserUrl() + '/venue/add_to_cart';
      const body = { user_id: '0123', venue_id: venue["venue_id"], quantity: 0 };
      await axios.post(apiUrl, body, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAddToCart = async () => {
    toast.success(venue.title + " has been added to cart!");
    setCartVenueItems([venue]);
    setCartCount(cartItemCount => cartItemCount+1);
    history.push({
      pathname: '/shoppingcart',
      state: { cartAddons, venue, guestCount }
    });
  };

  const scrollLeft = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollBy({
        left: -300, // Adjust scroll amount as needed
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollBy({
        left: 300, // Adjust scroll amount as needed
        behavior: 'smooth'
      });
    }
  };

  const groupAddonsByType = (addons) => {
    if (!addons || addons.length === 0) {
      return {};
    }

    return addons.reduce((acc, addon) => {
      const type = addon.addonType || 'Other';
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(addon);
      return acc;
    }, {});
  };

  const calculateTotal = () => {
    const addonsTotal = cartAddons.reduce((total, item) => total + (item.price || 0), 0);
    const venuePrice = venue ? venue.startPrice : 0;
    return addonsTotal + venuePrice;
  };

  const groupedAddons = groupAddonsByType(cartAddons);

  return (
    <div className="container mt-4">
      <div>
        <h1>{venue.title}</h1>
        {venue.VenueImages && venue.VenueImages.length > 0 ? (
          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <button onClick={scrollLeft} style={{
              position: 'absolute',
              left: 0,
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              border: 'none',
              padding: '0.5rem 1rem',
              cursor: 'pointer',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px'
            }} >&lt;
            </button>
            <div ref={imageContainerRef} style={{ display: 'flex', overflowX: 'auto', gap: '10px', scrollBehavior: 'smooth' }}>
              <div style={{ flex: '0 0 60%', height: '400px' }}>
                <img
                  src={venue.VenueImages[0].url}
                  alt={`Venue Image 1`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              </div>
              <div style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateRows: 'repeat(2, 195px)', gap: '10px', flex: '0 0 auto' }}>
                {venue.VenueImages.slice(1).map((image, index) => (
                  <div key={index} style={{ width: '300px', height: '195px' }}>
                    <img
                      src={image.url}
                      alt={`Venue Image ${index + 2}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <button onClick={scrollRight} style={{
              position: 'absolute',
              right: 0,
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              border: 'none',
              padding: '0.5rem 1rem',
              cursor: 'pointer',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '24px'
            }}>&gt;
            </button>
          </div>
        ) : (
          <p>No images available</p>
        )}

        <br></br>
        <br></br>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="div">
                  {venue.title}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.875rem', // Adjust font size to match Typography
                    borderRadius: 1,
                  }}
                >
                  <StarHalfOutlinedIcon sx={{ color: 'text.secondary', fontSize: '1rem' }} />
                  <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
                    4.95
                  </Typography>
                </Box>
              </Box>
              <Divider style={{ margin: '10px 0' }} />
              <Typography variant="body2">
                <strong>Address</strong>: {venue.address}, {venue.state}, {venue.zipcode}
              </Typography>
              <Typography variant="body2">
                <strong>Neighborhood</strong>: {venue.neighborhood}
              </Typography>
              <Divider style={{ margin: '10px 0' }} />
              <Typography variant="h6">Contact Info</Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon style={{ color: '#8e44ad' }} />
                  </ListItemIcon>
                  <ListItemText primary={venue.contact.phone} />
                </ListItem>
                <ListItem>
                  <Link href={venue.website} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
                    <ListItemIcon>
                      <LanguageIcon style={{ color: '#8e44ad' }} />
                    </ListItemIcon>
                    <ListItemText primary={venue.website.replace('https://www.', '').replace('http://www.', '').replace('www.', '')} />
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href={venue.contact.instagram} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', color: 'inherit', textDecoration: 'none' }}>
                    <ListItemIcon>
                      <InstagramIcon style={{ color: '#8e44ad' }} />
                    </ListItemIcon>
                    <ListItemText primary={venue.contact.instagram.replace('https://www.', '').replace('http://www.', '').replace('www.', '').replace('instagram.com/', '')} />
                  </Link>
                </ListItem>
              </List>
            </Paper>
            
            <Paper ref={CartRef} elevation={3} style={{ padding: '20px', position: 'sticky', top: '20px' }}>
              <Typography variant="h6" gutterBottom>${venue.startPrice} / night</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>4.9 · 275 reviews</Typography>
              <Divider style={{ margin: '10px 0' }} />
              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  <Typography variant="body2" gutterBottom>Guests</Typography>
                  {isEditing ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        type="number"
                        value={newGuestCount}
                        onChange={handleInputChange}
                        size="small"
                        variant="outlined"
                        inputProps={{ min: 1 }}
                        style={{ width: '100px', marginRight: '10px' }}
                      />
                      <IconButton onClick={handleSaveClick} color="primary" size="small" style={{ marginRight: '5px' }}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton onClick={handleCancelClick} color="secondary" size="small">
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body2" color="textSecondary" style={{ marginRight: '10px' }}>
                        {guestCount.toLocaleString()} guests
                      </Typography>
                      <IconButton onClick={handleEditClick} color="default" size="small">
                        <EditIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
              <Typography variant="body2" gutterBottom>In-House Beverage Service</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>Production</Typography>
              <Typography variant="body2" gutterBottom>Security</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>In-House Security</Typography>
              <Typography variant="body2" gutterBottom>Cleaning</Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>In-House Cleaning</Typography>

              <Divider style={{ margin: '10px 0' }} />
              {Object.keys(groupedAddons).map((addonType) => (
                <div key={addonType}>
                  <Typography variant="h6">{addonType}</Typography>
                  {groupedAddons[addonType].map((addon) => (
                    <div key={addon.addonId}>
                      <Typography variant="body2" gutterBottom>{addon.companyName}</Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        {addon.price === null ? (
                          <span style={{ color: 'green' }}>PENDING</span>
                        ) : (
                          `$ ${addon.price}`
                        )}
                        {addon.customDetails && (
                          <Typography variant="body2" color="textSecondary">
                            {addon.customDetails}
                          </Typography>
                        )}
                        <IconButton onClick={() => handleDeleteAddon(addon.addonId)} size="small" style={{ marginLeft: '10px' }}>
                          <RemoveIcon />
                        </IconButton>
                      </Typography>
                    </div>
                  ))}
                </div>
              ))}

            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <IconButton onClick={scrollToCollaborators} color="primary">
                  <AddIcon />
                </IconButton>
            </div>
              <Divider style={{ margin: '10px 0' }} />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>Total </Typography>
                <Typography variant="h6" gutterBottom>${calculateTotal()}</Typography>
              </Box>
              <Divider style={{ margin: '10px 0' }} />
              <Button variant="contained" color="primary" fullWidth onClick={handleAddToCart}>Add to Cart</Button>
            </Paper>
          </Grid>


          <Grid item xs={12} md={8}>
            <Typography variant="h6">About</Typography>
            <Typography variant="body2" paragraph>
              {displayedDescription}
            </Typography>
            {isLongDescription && (
              <Typography variant="body2">
                <Link href="#" onClick={handleReadMore} style={{ textDecoration: 'none', color: '#8e44ad' }}>
                  {expanded ? 'SHOW LESS' : 'READ MORE'}
                </Link>
              </Typography>
            )}

            <Divider style={{ margin: '20px 0' }} />
            <Grid container spacing={2}>
            
        <Box sx={{ p: 4 }}>
        <Typography variant="h5" gutterBottom>Details</Typography>

        {/* Venue Capacity details here */}
        <Grid container spacing={3} alignItems="center">
          {/* Capacity Section */}
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>Capacity</Typography>
          </Grid>
          
          <Grid item xs={12} sm={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Number of Event Spaces: {venue.VenueSpaces ? venue.VenueSpaces.length : 0}
                </Typography>
                <Typography variant="body2">
                  Max Standing: {venue.VenueSpaces ? venue.VenueSpaces.reduce((sum, space) => sum + (space.standingCapacity || 0), 0) : 0}
                </Typography>
                <Typography variant="body2">
                  Max Seated: {venue.VenueSpaces ? venue.VenueSpaces.reduce((sum, space) => sum + (space.seatedCapacity || 0), 0) : 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Food Section */}
          {/* <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>Food</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {venue.VenueCapacity && venue.VenueCapacity.length > 0 && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2">Food Service {venue.VenueCapacity[0].num_event_spaces}</Typography>
                  <Typography variant="body2">Beverage Service {venue.VenueCapacity[0].max_standing}</Typography>
                </Grid>

              </Grid>
            )}
          </Grid> */}

          {/* Feature Section */}
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>Features</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={1}>
              {(() => {
                // Combine all features and equipment from venue spaces
                const allFeatures = new Set([
                  ...(venue.features || []),
                  ...(venue.VenueSpaces || []).flatMap(space => [
                    ...(space.features || [])
                  ])
                ]);
                
                // Convert Set back to Array and take first 6 items for initial display
                const displayFeatures = Array.from(allFeatures).slice(0, 6);
                
                return displayFeatures.map((feature, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Typography variant="body2">{feature}</Typography>
                  </Grid>
                ));
              })()}
            </Grid>
            <div>
              <Button 
                variant="outlined" 
                size="small" 
                style={{ marginTop: '10px', color: '#8e44ad', borderColor: '#8e44ad' }}
              >
                Show all {(() => {
                  const allFeatures = new Set([
                    ...(venue.features || []),
                    ...(venue.VenueSpaces || []).flatMap(space => [
                      ...(space.features || [])
                    ])
                  ]);
                  return allFeatures.size;
                })()} features
              </Button>
            </div>
          </Grid>
          
          {/* Ideal Events Section */}
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>Ideal Events</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={1}>
              {venue.IdealEvents && venue.IdealEvents.map((feature, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Typography variant="body2">{feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Technology Section */}
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>Technology</Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid container spacing={1}>
              {(() => {
                // Combine all features and equipment from venue spaces
                const allFeatures = new Set([
                  ...(venue.VenueSpaces || []).flatMap(space => [
                    ...(space.equipment || [])
                  ])
                ]);
                
                // Convert Set back to Array and take first 6 items for initial display
                const displayFeatures = Array.from(allFeatures).slice(0, 6);
                
                return displayFeatures.map((feature, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Typography variant="body2">{feature}</Typography>
                  </Grid>
                ));
              })()}
            </Grid>
            <div>
              <Button 
                variant="outlined" 
                size="small" 
                style={{ marginTop: '10px', color: '#8e44ad', borderColor: '#8e44ad' }}
              >
                Show all {(() => {
                  const allFeatures = new Set([
                    ...(venue.VenueSpaces || []).flatMap(space => [
                      ...(space.equipment || [])
                    ])
                  ]);
                  return allFeatures.size;
                })()} features
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
            </Grid>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="h2" gutterBottom sx={{ 
              fontSize: '32px',
              fontWeight: 300,
              mb: 4 
            }}>
              Spaces
            </Typography>
            
            <Grid container spacing={4}>
              {venue.VenueSpaces && venue.VenueSpaces.map((space, index) => (
                <Grid item xs={12} md={4} key={space.venueSpaceId}>
                  <Box sx={{ mb: 4 }}>
                    {/* Image Container */}
                    <Box 
                      sx={{ 
                        width: '100%',
                        height: 175,
                        mb: 2,
                        cursor: 'pointer',
                        '&:hover': {
                          opacity: 0.9
                        }
                      }}
                      onClick={() => setImageIsOpen(true)}
                    >
                      <img
                        src={space.imagesUrl?.[0]}
                        alt={space.title}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Box>

                    {/* Space Title */}
                    <Typography 
                      variant="h7" 
                      gutterBottom 
                      sx={{ 
                        fontSize: '20px',
                        fontWeight: 300
                      }}
                    >
                      {space.title}
                    </Typography>

                    {/* Capacity Information */}
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: 'text.secondary',
                        fontSize: '14px'
                      }}
                    >
                      {[
                        space.seatedCapacity && `SEATED: ${space.seatedCapacity}`,
                        space.standingCapacity && `STANDING: ${space.standingCapacity}`
                      ].filter(Boolean).join(' / ')}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/* Lightbox - updated to use selected space image */}
            {imageIsOpen && (
                <Lightbox
                    mainSrc={venue.VenueSpaces[0].imagesUrl?.[0]}
                    onCloseRequest={() => setImageIsOpen(false)}
                    imageTitle={venue.VenueSpaces[0].title}
                    imagePadding={0}
                    reactModalStyle={{
                        content: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                            top: '10%',
                            left: '10%',
                            right: '10%',
                            bottom: '10%',
                            padding: '0',
                        },
                    }}
                    customStyles={{
                        image: {
                            maxWidth: '80%',
                            maxHeight: '80%',
                            margin: 'auto',
                            display: 'block',
                            objectFit: 'contain',
                        },
                    }}
                />
            )}
            <Typography variant="body2" style={{ color: '#8e44ad', marginTop: '10px' }}>
              
            </Typography>
            <Divider style={{ margin: '20px 0' }} />

            <Typography variant="h6">Location</Typography>
            <Typography variant="body2" style={{ color: '#8e44ad', marginTop: '10px' }}>
              <a href="https://www.google.com/maps" style={{ textDecoration: 'none', color: '#8e44ad' }}>Open in Google Maps</a>
            </Typography>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?q=LUME+Studios&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
            </iframe>
          </Grid>
        </Grid>

        <div className="row">
          <Divider style={{ margin: '20px 0' }} />
          <CollaboratorModal
            open={modalOpen}
            onClose={handleCloseModal}
            onAddAddon={handleAddAddonFromModal}
            collaborator={modalContent}
          />
          <br></br>
          <div ref={previousCollaboratorsRef}>
            <div className={classes.previousCollaborators}>Previous Collaborators</div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Catering</div>
                <CardRow cards={addons} onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Sponsors</div>
                <CardRow cards={venueSponsors} onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
};

export default VenuePage;
