import React, { useState } from 'react';
import Card from './Card';
import './CardRow.css';

const CardRow = ({ cards, onCardClick, onQuickAdd }) => {
  const [startIndex, setStartIndex] = useState(0);
  const cardsPerPage = 3;

  const nextPage = () => {
    if (startIndex + cardsPerPage < cards.length) {
      setStartIndex(startIndex + cardsPerPage);
    }
  };

  const prevPage = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - cardsPerPage);
    }
  };

  const visibleCards = cards.slice(startIndex, startIndex + cardsPerPage);

  return (
    <div className="card-row">
      <button onClick={prevPage} disabled={startIndex === 0}>
        &lt;
      </button>
      <div className="cards">
        {visibleCards.map((card, index) => (
          <Card
            key={index}
            // addonId={card}
            companyName={card.companyName}
            addonType={card.addonType}
            desc={card.desc}
            count={card.count}
            seeMore={() => onCardClick(card)}
            onQuickAdd={() => onQuickAdd(card)}
          />
        ))}
      </div>
      <button onClick={nextPage} disabled={startIndex + cardsPerPage >= cards.length}>
        &gt;
      </button>
    </div>
  );
};

export default CardRow;
