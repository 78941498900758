import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import classes from '../components/collaborators.module.css';

import { Box, Typography, Button, Divider, Grid, Paper, TextField, IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import CardRow from 'components/Collaborators/CardRow';
import CollaboratorModal from 'components/Collaborators/CollaboratorModal';
import { UserContext } from '../contexts/UserContext';
import { getBackendUserUrl } from '../utils';

dayjs.extend(utc);
dayjs.extend(timezone);

const ShoppingCartPage = ({cartAddonItems}) => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { cartAddons, venue } = location.state || {};

  const [cartItems, setCartItems] = useState(cartAddonItems || []);
  const [recommendedAddons, setRecommendedAddons] = useState([]);
  const [recommendedSponsors, setRecommendedSponsors] = useState([]);
  const [error, setError] = useState('');

  const [totalPrice, setTotalPrice] = useState(0);
  //Modal 
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', subtitle: '', description: '', price: 0 });

  //Guest Count
  const [guestCount, setGuestCount] = useState(150);
  const [isEditingGuest, setIsEditingGuest] = useState(false);
  const [newGuestCount, setNewGuestCount] = useState(guestCount);

  //Event Vision
  const [eventVision, setEventVision] = useState('A grand networking event connecting top industry leaders.');
  const [isEditingVision, setIsEditingVision] = useState(false);
  const [newEventVision, setNewEventVision] = useState(eventVision);

  //Event Target Audience 
  const [targetAudience, setTargetAudience] = useState(['Founders', 'VCs', 'Software Engineers']);
  const [isEditingAudience, setIsEditingAudience] = useState(false);
  const [newTargetAudience, setNewTargetAudience] = useState(targetAudience.join(', '));

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, 'hour'));
  const [isEditingDates, setIsEditingDates] = useState(false);

  const [eventName, setEventName] = useState('My Event');
  const [isEditingEventName, setIsEditingEventName] = useState(false);
  const [newEventName, setNewEventName] = useState(eventName);

  // Handlers for Guest Count
  const handleEditGuestClick = () => setIsEditingGuest(true);
  const handleSaveGuestClick = () => {
    setGuestCount(newGuestCount);
    setIsEditingGuest(false);
  };
  const handleCancelGuestClick = () => setIsEditingGuest(false);


  // Handlers for Event Vision
  const handleEditVisionClick = () => setIsEditingVision(true);
  const handleSaveVisionClick = () => {
    setEventVision(newEventVision);
    setIsEditingVision(false);
  };
  const handleCancelVisionClick = () => setIsEditingVision(false);


  // Handlers for Target Audience
  const handleEditAudienceClick = () => setIsEditingAudience(true);
  const handleSaveAudienceClick = () => {
    const audienceArray = newTargetAudience.split(',').map(item => item.trim());
    setTargetAudience(audienceArray);
    setIsEditingAudience(false);
  };
  const handleCancelAudienceClick = () => setIsEditingAudience(false);

  const handleEditDatesClick = () => setIsEditingDates(true);

  const handleSaveDatesClick = () => {
    if (endDate.isBefore(startDate)) {
      toast.error("End date cannot be before start date");
      return;
    }
    setIsEditingDates(false);
  };

  const formatDateRange = (start, end) => {
    return `${start.format('MMM D, YYYY h:mm A')} - ${end.format('MMM D, YYYY h:mm A')}`;
  };

  const handleEditEventNameClick = () => setIsEditingEventName(true);
  
  const handleSaveEventNameClick = () => {
    if (newEventName.trim() === '') {
      toast.error("Event name cannot be empty");
      return;
    }
    setEventName(newEventName);
    setIsEditingEventName(false);
  };

  const handleCancelEventNameClick = () => {
    setNewEventName(eventName);
    setIsEditingEventName(false);
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        if (cartAddons) {
          setCartItems(cartAddons);
        }
  
        if (venue && venue.VenueAddOns) {
          setRecommendedAddons(venue.VenueAddOns);
          setRecommendedSponsors(venue.VenueSponsors);
        }
        console.log("cartAddons", cartAddons);
      } catch (err) {
        setError(err.message);
      }
    };
  
    fetchCartItems();
  }, [cartAddons, venue]);
  

  const handleQuickAdd = (addon) => {
    setCartItems((prevCartItems) => [...prevCartItems, addon]);
    console.log("addon", addon);
    console.log("cartItems", cartItems);
    toast.success(`${addon.companyName} added to cart`);
  };

  const handleRemoveAddon = (addonId) => {
    setCartItems((prevCartItems) => prevCartItems.filter(item => item.addonId !== addonId));
    toast.info('Addon removed from cart');
  };

  // TODO: Updated Post Messages, now need to pull conversation Messages to load in dynamically
  const handleCheckoutButtonClick = async () => {
    console.log("handleCheckoutButtonClick", user);
    const apiUrl = getBackendUserUrl() + "/event/create_event";
    // Prepare the event payload
    const eventPayload = {
      title: eventName,
      hostId: user.userId,
      venue: venue,
      guestCount: guestCount,
      eventVision: eventVision,
      targetAudience: targetAudience,
      eventStartDate: startDate.toISOString(),
      eventEndDate: endDate.toISOString(),
      addons: cartItems
    };

    console.log("eventPayload:", eventPayload);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventPayload),
      });

      const responseData = await response.json();
      console.log("Response status:", response.status);
      console.log("Response data:", responseData);

      if (!response.ok) {
        throw new Error(responseData.detail || 'Failed to create event');
      }

      console.log("Event created successfully", responseData);
      // Redirect to the event page or success page
      window.location.href = "/myevents";
    } catch (error) {
      console.error("Error creating event:", error.message);
      // Handle the error (e.g., show an error message to the user)
      toast.error(`Failed to create event: ${error.message}`);
    }
  };

  const handleOpenModal = (collaborator) => {
    setModalContent({ collaborator });
    console.log("HANDLE OPEN ----- ", modalContent);
    // setSelectedCollaborator(collaborator);
    setModalOpen(true);
  };

  const handleCloseModal = () => setModalOpen(false);


  const handleAddAddonFromModal = (newAddon) => {
    // setCartAddons([...cartAddons, newAddon]);
    setCartItems([...cartItems, newAddon]);
    // setCartAddonItems([...cartAddons, newAddon]);
    // setCartCount(prevCount => prevCount + 1);
    handleCloseModal();
  };

  // const scrollToCart = () => {
  //   if (CartRef.current) {
  //     CartRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  const calculateTotal = () => {
    const addonsTotal = cartItems.reduce((total, item) => total + item.price, 0);
    const venuePrice = venue ? venue.startPrice : 0;
    return addonsTotal + venuePrice;
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Box sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Request to book
        </Typography>

        <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
          <Typography variant="body1" gutterBottom>
            Lower price.
          </Typography>
          <Typography variant="body2">
            Your dates are $373 less than the avg. rate of the last 60 days.
          </Typography>
        </Paper>

        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Your Booking
              </Typography>
              <Divider sx={{ my: 2 }} />

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Event Dates</Typography>
                  {isEditingDates ? (
                    <Box display="flex" alignItems="center">
                      <DateTimePicker
                        label="Start Date & Time"
                        value={startDate}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                      <Typography sx={{ mx: 1 }}>-</Typography>
                      <DateTimePicker
                        label="End Date & Time"
                        value={endDate}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                      <Button variant="contained" onClick={handleSaveDatesClick} sx={{ ml: 2 }}>Save</Button>
                    </Box>
                  ) : (
                    <>
                      <Typography variant="body1">{formatDateRange(startDate, endDate)}</Typography>
                      <Button variant="text" onClick={() => setIsEditingDates(true)}>Edit</Button>
                    </>
                  )}
                </Box>
              </LocalizationProvider>

              {/* Guest Section */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 'bold'}}>Guests</Typography>
          {isEditingGuest ? (
            <Box display="flex" alignItems="center">
              <TextField
                type="number"
                value={newGuestCount}
                onChange={(e) => setNewGuestCount(e.target.value)}
                size="small"
                style={{ width: '120px', marginRight: '10px' }}
              />
              <Button variant="text" onClick={handleSaveGuestClick}>Save</Button>
              <Button variant="text" onClick={handleCancelGuestClick}>Cancel</Button>
            </Box>
          ) : (
            <Typography variant="body1">{guestCount} guest{guestCount > 1 ? 's' : ''}</Typography>
          )}
          {!isEditingGuest && <Button variant="text" onClick={handleEditGuestClick}>Edit</Button>}
        </Box>

            </Paper>

            <Paper elevation={1} sx={{ p: 2, mb: 4 }}>
            <Typography variant="h6" gutterBottom>
                Your Event Details
              </Typography>
              <Divider sx={{ my: 2 }} />

              {/* Event Name */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Event Name</Typography>
                {isEditingEventName ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      value={newEventName}
                      onChange={(e) => setNewEventName(e.target.value)}
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    <Button variant="contained" onClick={handleSaveEventNameClick} sx={{ mr: 1 }}>Save</Button>
                    <Button variant="outlined" onClick={handleCancelEventNameClick}>Cancel</Button>
                  </Box>
                ) : (
                  <>
                    <Typography variant="body1">{eventName}</Typography>
                    <Button variant="text" onClick={handleEditEventNameClick}>Edit</Button>
                  </>
                )}
              </Box>

              {/* Event Vision Section */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h7" sx={{ fontWeight: 'bold'}}>Event Vision</Typography>
                {isEditingVision ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      multiline
                      value={newEventVision}
                      onChange={(e) => setNewEventVision(e.target.value)}
                      size="small"
                      style={{ width: '100%', marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}
                    />
                    <Button variant="text" onClick={handleSaveVisionClick}>Save</Button>
                    <Button variant="text" onClick={handleCancelVisionClick}>Cancel</Button>
                  </Box>
                ) : (
                  <Typography variant="body1" sx={{ marginLeft: '10px', flexGrow: 1 }}>{eventVision}</Typography>
                )}
                {!isEditingVision && <Button variant="text" onClick={handleEditVisionClick}>Edit</Button>}
              </Box>

              {/*Target Audience  */}
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold'}}>Target Audience</Typography>
                {isEditingAudience ? (
                  <Box display="flex" alignItems="center">
                    <TextField
                      multiline
                      value={newTargetAudience}
                      onChange={(e) => setNewTargetAudience(e.target.value)}
                      size="small"
                      style={{ width: '100%', marginRight: '10px' }}
                      helperText="Separate audiences with commas"
                    />
                    <Button variant="text" onClick={handleSaveAudienceClick}>Save</Button>
                    <Button variant="text" onClick={handleCancelAudienceClick}>Cancel</Button>
                  </Box>
                ) : (
                  <Typography variant="body1">{targetAudience.join(', ')}</Typography>
                )}
                {!isEditingAudience && <Button variant="text" onClick={handleEditAudienceClick}>Edit</Button>}
              </Box>
              
            </Paper>
          </Grid>

          <Grid item xs={12} md={5}>
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Venue and Add-ons
              </Typography>
              <Divider sx={{ my: 2 }} />
              {venue && (
                <Box display="flex" alignItems="center" mb={2}>
                  <img
                    src={venue.VenueImages && venue.VenueImages[0].url}
                    alt={venue.title}
                    style={{ borderRadius: '8px', marginRight: '16px', width: '150px', height: '100px', objectFit: 'cover' }}
                  />
                  <Box>
                    <Typography variant="h6">{venue.title}</Typography>
                    {/* <Typography variant="body2">{venue.desc}</Typography> */}
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">Price</Typography>
                      <Typography variant="body2">${venue.startPrice}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Divider sx={{ my: 2 }} />
              {cartItems.map((item, index) => (
                <Box key={index} mb={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box>
                      <Typography variant="body1">{item.companyName}</Typography>
                      <Typography variant="body2">{item.addonType}</Typography>
                      {/* <Typography variant="body2">{item.desc}</Typography> */}
                    </Box>
                    <IconButton onClick={() => handleRemoveAddon(item.addonId)}>
                      <RemoveIcon />
                    </IconButton>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body2">Price</Typography>
                    <Typography variant="body2">
                    {item.price === null ? (
                          <span style={{ color: 'green' }}>PENDING</span>
                        ) : (
                          `$ ${item.price}`
                        )}
                        </Typography>
                  </Box>
                  {index < cartItems.length - 1 && <Divider sx={{ my: 2 }} />}
                </Box>
              ))}

              <Divider sx={{ my: 2 }} />

              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1" fontWeight="bold">Total (USD)</Typography>
                <Typography variant="body1" fontWeight="bold">${calculateTotal()}</Typography>
              </Box>
            </Paper>
            <Box mt={4}>
              <Button variant="contained" color="primary" size="large" onClick={handleCheckoutButtonClick}>
                Checkout ${calculateTotal()} now
              </Button>
            </Box>
          </Grid>
        </Grid>

        <div className="row">
          <Divider style={{ margin: '20px 0' }} />
          <CollaboratorModal
            open={modalOpen}
            onClose={handleCloseModal}
            onAddAddon={handleAddAddonFromModal}
            collaborator={modalContent}
          />
        <Box mt={6}>
          <Typography variant="h6">Recommended Add-ons to Complete your Event</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Catering</div>
                <CardRow cards={recommendedAddons} onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.sponsors}>Sponsors</div>
                <CardRow cards={recommendedSponsors}  onCardClick={handleOpenModal} onQuickAdd={handleQuickAdd} />
              </Grid>
            </Grid>
          </Box>
          </div>

      </Box>
      <ToastContainer />
    </div>
  );
};

export default ShoppingCartPage;
