import React, { useState, useRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { getBackendUserUrl } from 'utils'
import { Autocomplete, LoadScript } from '@react-google-maps/api'
import { fetchGeocodeData } from 'utils'

const VenueFormPage = () => {
    const prods = JSON.parse(localStorage.getItem('products')) || []

    const [venueName, setVenueName] = useState('')
    const [description, setDescription] = useState('')
    const [price, setPrice] = useState('')
    const [category, setCategory] = useState('')
    const [sellerName, setSellerName] = useState('')
    const [contactDetails, setContactDetails] = useState('')
    const [address, setAddress] = useState('')
    const [products, setProducts] = useState(prods)
    const [id, setId] = useState(prods.length + 1)
    const [image, setImage] = useState(null)
    const [autocomplete, setAutocomplete] = useState(null)
    const inputRef = useRef(null)

    // Addons state
    const [hasCleaningService, setHasCleaningService] = useState(false)
    const [cleaningServiceName, setCleaningServiceName] = useState('')
    const [cleaningServicePrice, setCleaningServicePrice] = useState('')
    const [cleaningServiceDescription, setCleaningServiceDescription] =
        useState('')
    const [hasCateringService, setHasCateringService] = useState(false)
    const [cateringServiceName, setCateringServiceName] = useState('')
    const [cateringServicePrice, setCateringServicePrice] = useState('')
    const [cateringServiceDescription, setCateringServiceDescription] =
        useState('')

    const handleVenueNameChange = (e) => setVenueName(e.target.value)
    const handleDescriptionChange = (e) => setDescription(e.target.value)
    const handlePriceChange = (e) => setPrice(e.target.value)
    const handleImageChange = (e) => setImage(e.target.files[0])
    const handleCategoryChange = (e) => setCategory(e.target.value)
    const handleSellerNameChange = (e) => setSellerName(e.target.value)
    const handleContactDetailsChange = (e) => setContactDetails(e.target.value)
    const handleAddressChange = (e) => setAddress(e.target.value)

    const handlePlaceChanged = () => {
        const place = autocomplete.getPlace()
        if (place) {
            const addressComponents = place.address_components
            setAddress(place.formatted_address)
            const cityComponent = addressComponents.find((comp) =>
                comp.types.includes('locality')
            )
            const stateComponent = addressComponents.find((comp) =>
                comp.types.includes('administrative_area_level_1')
            )
            const zipcodeComponent = addressComponents.find((comp) =>
                comp.types.includes('postal_code')
            )
        }
    }

    const submitAddNewVenue = async () => {
        const formData = new FormData()
        const coordinates = await fetchGeocodeData(
            address,
            process.env.REACT_APP_GOOGLE_GEOENCODING_API_KEY
        )

        const venueData = {
            venueId: '',
            created: '',
            title: venueName,
            desc: description,
            startPrice: parseInt(price, 10),
            address,
            rating: 0,
            eventType: [category],
            latitude: coordinates[0],
            longitude: coordinates[1],
        }

        if (hasCateringService) {
            const addonDataCatering = {
                addonId: '',
                venueId: '',
                created: '',
                desc: cateringServiceDescription,
                price: parseInt(cateringServicePrice, 10),
                cost: true,
                addonType: 'Catering',
                companyName: cateringServiceName,
            }
            formData.append('venueAddons', JSON.stringify(addonDataCatering))
        }

        if (hasCleaningService) {
            const addonDataCleaning = {
                addonId: '',
                venueId: '',
                created: '',
                desc: cleaningServiceDescription,
                price: parseInt(cleaningServicePrice, 10),
                cost: true,
                addonType: 'Cleaning',
                companyName: cleaningServiceName,
            }
            formData.append(
                'venueAddons_cleaning',
                JSON.stringify(addonDataCleaning)
            )
        }

        formData.append('venue', JSON.stringify(venueData))
        formData.append('file', image)
        console.log(venueData)

        try {
            const apiUrl = getBackendUserUrl() + 'supabase/create_update_venue'
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            if (response.status === 200) {
                console.log('Venue and image uploaded successfully')
            } else {
                console.error('Error uploading venue:', response.statusText)
                toast.error(response.statusText)
            }
        } catch (error) {
            console.error('Error adding new venue:', error)
            if (error.response) {
                console.error('Error response:', error.response.data)
            }
            toast.error('Error adding new venue')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const errors = {}
        if (!venueName.trim()) errors.venueName = 'Venue Name is required'
        if (!description.trim()) errors.description = 'Description is required'
        if (!price.trim()) errors.price = 'Price is required'
        if (!category.trim()) errors.category = 'Category is required'
        if (!image) errors.image = 'Image is required'
        if (!sellerName.trim()) errors.sellerName = 'Seller Name is required'
        if (!contactDetails.trim())
            errors.contactDetails = 'Contact Details are required'
        if (!address.trim()) errors.address = 'Address is required'

        if (Object.keys(errors).length === 0) {
            const reader = new FileReader()
            reader.onloadend = async () => {
                const base64Image = reader.result
                const product = {
                    id,
                    venueName,
                    description,
                    price,
                    image: base64Image,
                    quantity: 1,
                    category,
                    sellerName,
                    contactDetails,
                }

                const updatedProducts = [...products, product]
                setProducts(updatedProducts)

                await submitAddNewVenue() // Ensure you wait for the submission
                toast.success('Product added successfully')
            }

            reader.readAsDataURL(image)
        } else {
            Object.values(errors).forEach((error) => toast.error(error))
        }
    }

    return (
        <LoadScript
            googleMapsApiKey={
                process.env.REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_API_KEY
            }
            libraries={['places']}
        >
            <div>
                <div className="container" style={{ width: '70%' }}>
                    <h2>Add Venue</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="venueName">Venue Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="venueName"
                                value={venueName}
                                onChange={handleVenueNameChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description:</label>
                            <textarea
                                className="form-control"
                                id="description"
                                value={description}
                                onChange={handleDescriptionChange}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="price">Price:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="price"
                                value={price}
                                onChange={handlePriceChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="category">Category:</label>
                            <select
                                className="form-control"
                                id="category"
                                value={category}
                                onChange={handleCategoryChange}
                            >
                                <option value="">Select a category</option>
                                <option value="TechWeek">TechWeek</option>
                                <option value="Bar">Bar</option>
                                <option value="Gallery">Gallery</option>
                                <option value="Club">Club</option>
                                <option value="Dinner">Dinner</option>
                                <option value="Restaurant">Restaurant</option>
                                <option value="Conference">Conference</option>
                                <option value="EventSpace">EventSpace</option>
                                <option value="Corporate">Corporate</option>
                                <option value="SpeakerPanel">
                                    SpeakerPanel
                                </option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sellerName">Seller Name:</label>
                            <input
                                type="text"
                                className="form-control"
                                id="sellerName"
                                value={sellerName}
                                onChange={handleSellerNameChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactDetails">
                                Contact Details:
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="contactDetails"
                                value={contactDetails}
                                onChange={handleContactDetailsChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="address">Address:</label>
                            <Autocomplete
                                onLoad={(autocomplete) =>
                                    setAutocomplete(autocomplete)
                                }
                                onPlaceChanged={handlePlaceChanged}
                            >
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    value={address}
                                    onChange={handleAddressChange}
                                    ref={inputRef}
                                    placeholder="Enter your address"
                                />
                            </Autocomplete>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="image">Image: </label>
                            <input
                                type="file"
                                className="form-control-file"
                                id="image"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </div>

                        {/* Addons Section */}
                        <h3>Addons</h3>
                        <div className="form-group">
                            <label>Cleaning Service:</label>
                            <input
                                type="checkbox"
                                checked={hasCleaningService}
                                onChange={(e) =>
                                    setHasCleaningService(e.target.checked)
                                }
                            />
                            {hasCleaningService && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="cleaningServiceName">
                                            Service Name:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cleaningServiceName"
                                            value={cleaningServiceName}
                                            onChange={(e) =>
                                                setCleaningServiceName(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cleaningServicePrice">
                                            Price:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cleaningServicePrice"
                                            value={cleaningServicePrice}
                                            onChange={(e) =>
                                                setCleaningServicePrice(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cleaningServiceDescription">
                                            Description:
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="cleaningServiceDescription"
                                            value={cleaningServiceDescription}
                                            onChange={(e) =>
                                                setCleaningServiceDescription(
                                                    e.target.value
                                                )
                                            }
                                        ></textarea>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Catering Service:</label>
                            <input
                                type="checkbox"
                                checked={hasCateringService}
                                onChange={(e) =>
                                    setHasCateringService(e.target.checked)
                                }
                            />
                            {hasCateringService && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="cateringServiceName">
                                            Service Name:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cateringServiceName"
                                            value={cateringServiceName}
                                            onChange={(e) =>
                                                setCateringServiceName(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cateringServicePrice">
                                            Price:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cateringServicePrice"
                                            value={cateringServicePrice}
                                            onChange={(e) =>
                                                setCateringServicePrice(
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="cateringServiceDescription">
                                            Description:
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="cateringServiceDescription"
                                            value={cateringServiceDescription}
                                            onChange={(e) =>
                                                setCateringServiceDescription(
                                                    e.target.value
                                                )
                                            }
                                        ></textarea>
                                    </div>
                                </>
                            )}
                        </div>

                        <button type="submit" className="btn btn-primary mt-3">
                            Submit
                        </button>
                    </form>
                    <ToastContainer />
                </div>
            </div>
        </LoadScript>
    )
}

export default VenueFormPage
