import React, { useState } from 'react';
import { 
  Paper, Typography, Box, Checkbox, IconButton, List, ListItem, 
  ListItemText, ListItemIcon, Chip, Dialog, DialogTitle, DialogContent, 
  DialogActions, Button, TextField
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const VendorRequirements = ({ vendor, requirements, onUpdateRequirements }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newRequirement, setNewRequirement] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const handleToggle = (index) => {
    const updatedRequirements = requirements.map((req, i) => 
      i === index ? { ...req, completed: !req.completed } : req
    );
    onUpdateRequirements(updatedRequirements);
  };

  const handleAddRequirement = () => {
    if (newRequirement.trim()) {
      const updatedRequirements = [
        ...requirements,
        { text: newRequirement.trim(), completed: false }
      ];
      onUpdateRequirements(updatedRequirements);
      setNewRequirement('');
      setOpenDialog(false);
    }
  };

  const handleEditRequirement = () => {
    if (newRequirement.trim() && editingIndex !== null) {
      const updatedRequirements = requirements.map((req, i) => 
        i === editingIndex ? { ...req, text: newRequirement.trim() } : req
      );
      onUpdateRequirements(updatedRequirements);
      setNewRequirement('');
      setEditingIndex(null);
      setOpenDialog(false);
    }
  };

  const openEditDialog = (index) => {
    setEditingIndex(index);
    setNewRequirement(requirements[index].text);
    setOpenDialog(true);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">{vendor.name} Requirements</Typography>
        <Box>
          <IconButton color="primary" onClick={() => setOpenDialog(true)}>
            <AddIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => window.location.href = `mailto:${vendor.email}`}>
            <EmailIcon />
          </IconButton>
        </Box>
      </Box>
      <List>
        {requirements.map((requirement, index) => (
          <ListItem key={index} dense button onClick={() => handleToggle(index)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={requirement.completed}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText 
              primary={requirement.text}
              secondary={
                <Chip 
                  size="small" 
                  label={requirement.completed ? "Completed" : "Pending"} 
                  color={requirement.completed ? "success" : "warning"}
                />
              }
            />
            <IconButton edge="end" onClick={() => openEditDialog(index)}>
              <EditIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingIndex !== null ? "Edit Requirement" : "Add New Requirement"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Requirement"
            type="text"
            fullWidth
            value={newRequirement}
            onChange={(e) => setNewRequirement(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={editingIndex !== null ? handleEditRequirement : handleAddRequirement} color="primary">
            {editingIndex !== null ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default VendorRequirements;