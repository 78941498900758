import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import VenueBoxMarketplace from '../components/Venue/VenueBoxMarketplace'
import FilterBar from 'components/FilterBar/FilterBar'
import SortDropdown from 'components/sortDropDown'
import FilterListIcon from '@mui/icons-material/FilterList'
import { getBackendUserUrl } from '../utils'
import AddressSearch from 'components/AddressSearch'
import { haversine } from '../utils'
import { UserContext } from 'contexts/UserContext'

const MarketplacePage = () => {
    const { user, loading, hasRole } = useContext(UserContext);
    // const hasBetaAccess = user && hasRole(['admin']);

    const [fullSetOfVenuesFromBackend, setfullSetOfVenuesFromBackend] =
        useState([])
    const [venues, setVenues] = useState([])
    const [error, setError] = useState(false)
    const [sort, setSort] = useState('newest')
    const [filter, setFilter] = useState('All')
    const [priceRange, setPriceRange] = useState([0, 1000000])
    const [open, setOpen] = useState(false)
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(1000)
    const [desiredCoordinates, setDesiredCoordinates] = useState(null)
    const [desiredMaxDistance, setDesiredMaxDistance] = useState(25)

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const apiUrl = getBackendUserUrl() + '/venue/venues'
                const response = await axios.post(apiUrl)
                if (Array.isArray(response.data.data)) {
                    setVenues(response.data.data)
                    setfullSetOfVenuesFromBackend(response.data.data)
                } else {
                    throw new Error('Response is not an array')
                }
            } catch (err) {
                setError(err.message)
            }
        }
        fetchVenues()
    }, [])

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleApplyPriceFilter = () => {
        setPriceRange([minPrice, maxPrice])
        handleClose()
    }

    const filteredVenues = venues.filter(
        (venue) =>
            (filter === 'All' ||
                (venue.eventType && venue.eventType.includes(filter))) &&
            venue.startPrice >= priceRange[0] &&
            venue.startPrice <= priceRange[1]
    )

    const sortVenues = (venues) => {
        switch (sort) {
            case 'oldest':
                return venues.sort(
                    (a, b) => new Date(a.created) - new Date(b.created)
                )
            case 'newest':
                return venues.sort(
                    (a, b) => new Date(b.created) - new Date(a.created)
                )
            case 'lowerPrice':
                return venues.sort((a, b) => a.startPrice - b.startPrice)
            case 'higherPrice':
                return venues.sort((a, b) => b.startPrice - a.startPrice)
            default:
                return venues
        }
    }

    useEffect(() => {
        if (desiredCoordinates) {
            const filterVenuesBasedOnDistance = (venues) => {
                return venues.filter((venue) => {
                    if (venue.latitude && venue.longitude) {
                        const distance = haversine(
                            desiredCoordinates[0],
                            desiredCoordinates[1],
                            venue.latitude,
                            venue.longitude
                        )
                        return distance <= desiredMaxDistance
                    }
                    return false // Skip venues without coordinates
                })
            }

            const filteredVenuesBasedOnDistance =
                filterVenuesBasedOnDistance(venues)
            setVenues(filteredVenuesBasedOnDistance)
        }
    }, [desiredCoordinates, desiredMaxDistance])

    const sortedVenues = sortVenues(filteredVenues)

    return (
        <div className="container mt-4">
            <div>
                <FilterBar setFilter={setFilter} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <SortDropdown setSort={setSort} />
                    <Button
                        variant="outlined"
                        startIcon={<FilterListIcon />}
                        style={{ marginLeft: '10px' }}
                        onClick={handleOpen}
                    >
                        Filter
                    </Button>
                    {hasRole(['admin']) && (
                        <div style={{ marginLeft: '10px' }}>
                            <AddressSearch
                                setCoordinates={setDesiredCoordinates}
                                setDistance={setDesiredMaxDistance}
                            />
                        </div>
                    )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                    }}
                >
                    <h1>Venues</h1>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        {sortedVenues.map((venue) => (
                            <VenueBoxMarketplace
                                key={venue.venueId}
                                venue={venue}
                            />
                        ))}
                    </div>
                </div>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Filter by Price</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Minimum Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={minPrice}
                            onChange={(e) =>
                                setMinPrice(parseFloat(e.target.value))
                            }
                        />
                        <TextField
                            margin="dense"
                            label="Maximum Price"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={maxPrice}
                            onChange={(e) =>
                                setMaxPrice(parseFloat(e.target.value))
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleApplyPriceFilter}>Next</Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default MarketplacePage
