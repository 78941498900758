import { useState, useEffect } from 'react';
import { 
  Paper, Grid, Box, Typography, IconButton, Button, 
  LinearProgress, InputAdornment, TextField 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const BudgetChart = ({ annualBudget = 0, totalSpend = 0, onBudgetUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempBudget, setTempBudget] = useState(annualBudget.toString());

  useEffect(() => {
    setTempBudget(annualBudget.toString());
  }, [annualBudget]);

  const handleSave = () => {
    const newBudget = parseFloat(tempBudget);
    if (!isNaN(newBudget) && newBudget >= 0) {
      onBudgetUpdate(newBudget);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setTempBudget(annualBudget.toString());
    setIsEditing(false);
  };

  const progressValue = annualBudget > 0 ? (totalSpend / annualBudget) * 100 : 0;

  return (
    <Paper 
      elevation={3}
      sx={{ 
        p: 3,
        borderRadius: 2,
        background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-2px)',
        }
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                color: '#2c3e50'
              }}
            >
              Annual Budget for 2024
            </Typography>
            {!isEditing && (
              <IconButton 
                onClick={() => setIsEditing(true)}
                sx={{ 
                  color: '#7f8c8d',
                  '&:hover': {
                    color: '#2980b9',
                    backgroundColor: 'rgba(41, 128, 185, 0.1)'
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>

          {!isEditing ? (
            <Box mt={3}>
              <Box mb={1}>
                <Typography variant="body1" sx={{ fontWeight: 500, color: '#34495e' }}>
                  {formatCurrency(totalSpend)} spent of {formatCurrency(annualBudget)}
                </Typography>
              </Box>
              <LinearProgress 
                variant="determinate" 
                value={Math.min(progressValue, 100)}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: 'rgba(189, 195, 199, 0.2)',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: progressValue > 90 ? '#e74c3c' : '#2ecc71',
                  }
                }}
              />
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2" color="textSecondary">
                  {formatCurrency(0)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatCurrency(annualBudget)}
                </Typography>
              </Box>
              <Typography 
                variant="caption" 
                sx={{ 
                  display: 'block',
                  mt: 2,
                  color: '#7f8c8d'
                }}
              >
                {progressValue > 90 
                  ? '⚠️ Approaching budget limit'
                  : 'Track your annual event spending'}
              </Typography>
            </Box>
          ) : (
            <Box 
              mt={3} 
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                padding: 2,
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
              }}
            >
              <TextField
                fullWidth
                label="Annual Budget"
                type="number"
                value={tempBudget}
                onChange={(e) => setTempBudget(e.target.value)}
                variant="outlined"
                size="medium"
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
              <Box display="flex" gap={1}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  startIcon={<SaveIcon />}
                  sx={{
                    flex: 1,
                    background: 'linear-gradient(145deg, #2980b9 0%, #3498db 100%)',
                    '&:hover': {
                      background: 'linear-gradient(145deg, #2472a4 0%, #2980b9 100%)',
                    }
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  startIcon={<CancelIcon />}
                  sx={{
                    flex: 1,
                    borderColor: '#7f8c8d',
                    color: '#7f8c8d',
                    '&:hover': {
                      borderColor: '#95a5a6',
                      backgroundColor: 'rgba(127, 140, 141, 0.1)'
                    }
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BudgetChart;