import React, { useState, useEffect } from 'react';
import {
  TextField, Select, MenuItem, IconButton, Chip, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Typography, Box, Grid, Paper
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import VendorRequirements from './VendorRequirements';

const initialTasks = [
  { id: 1, task: 'Confirm venue booking', status: 'Completed', type: 'Venue', party: 'Lume Studios, NYC', cost: 5000, contact: 'venue@example.com', priority: 'High', comments: 'Deposit paid' },
  { id: 2, task: 'Hire caterer', status: 'In Progress', type: 'Catering', party: 'Tastings NYC | SoFlo Catering', cost: 3000, contact: 'catering@example.com', priority: 'Medium', comments: 'Negotiating menu options' },
  { id: 3, task: 'Book entertainment', status: 'Not Started', type: 'Entertainment', party: 'TechCorp', cost: null, contact: 'entertainment@techcorp.com', priority: 'Low', comments: 'Awaiting proposals' },
];

const taskTypes = ['Venue', 'Catering', 'Entertainment', 'Marketing', 'Logistics', 'Sponsorship', 'Speaking'];

const TasksTable = ({ eventDetails }) => {
  const [tasks, setTasks] = useState(initialTasks);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState('');
  const [newTaskDialogOpen, setNewTaskDialogOpen] = useState(false);
  const [newTask, setNewTask] = useState({
    task: '',
    status: 'Not Started',
    type: '',
    party: '',
    cost: '',
    contact: '',
    priority: 'Medium',
    comments: ''
  });
  const [partiesInvolved, setPartiesInvolved] = useState([]);
  const [vendorRequirements, setVendorRequirements] = useState({});

  useEffect(() => {
    // Extract parties involved from eventDetails
    const parties = [
      { name: eventDetails.venue.name, contacts: ['venue@example.com'] },
      ...eventDetails.addons.map(addon => ({ name: addon.name, contacts: [`${addon.category.toLowerCase()}@example.com`] })),
      ...eventDetails.sponsors.map(sponsor => ({ name: sponsor.name, contacts: [`contact@${sponsor.name.toLowerCase().replace(' ', '')}.com`] }))    ];
    setPartiesInvolved(parties);

    // Initialize vendor requirements
    const initialRequirements = {};
    parties.forEach(party => {
      initialRequirements[party.name] = [
        { text: 'Submit contract', completed: false },
        { text: 'Provide insurance certificate', completed: false },
        { text: 'Confirm event details', completed: false },
      ];
    });
    setVendorRequirements(initialRequirements);
  }, [eventDetails]);

  const handleEmailClick = (contact) => {
    setSelectedContact(contact);
    setEmailDialogOpen(true);
  };

  const handleCloseEmailDialog = () => {
    setEmailDialogOpen(false);
  };

  const sendEmail = () => {
    window.location.href = `mailto:${selectedContact}`;
    handleCloseEmailDialog();
  };

  const handleOpenNewTaskDialog = () => {
    setNewTaskDialogOpen(true);
  };

  const handleCloseNewTaskDialog = () => {
    setNewTaskDialogOpen(false);
    setNewTask({
      task: '',
      status: 'Not Started',
      type: '',
      party: '',
      cost: '',
      contact: '',
      priority: 'Medium',
      comments: ''
    });
  };

  const handleNewTaskChange = (event) => {
    const { name, value } = event.target;
    setNewTask(prevTask => ({
      ...prevTask,
      [name]: value
    }));
  };

  const handleAddNewTask = () => {
    const taskToAdd = {
      ...newTask,
      id: tasks.length + 1,
      cost: newTask.cost ? parseFloat(newTask.cost) : null
    };
    setTasks(prevTasks => [...prevTasks, taskToAdd]);
    handleCloseNewTaskDialog();
  };

  const handleUpdateRequirements = (vendorName, updatedRequirements) => {
    setVendorRequirements(prev => ({
      ...prev,
      [vendorName]: updatedRequirements
    }));
  };

  const columns = [
    { field: 'task', headerName: 'Task', flex: 1 },
    { field: 'status', headerName: 'Status', width: 130,
      renderCell: (params) => (
        <Chip label={params.value} color={
          params.value === 'Completed' ? 'success' :
          params.value === 'In Progress' ? 'warning' :
          'default'
        } />
      )
    },
    { field: 'type', headerName: 'Type', width: 130 },
    { field: 'party', headerName: 'Party Involved', width: 150 },
    { field: 'cost', headerName: 'Cost', type: 'number', width: 100,
      valueFormatter: (params) => {
        if (params == null || params.value == null) return '';
        return `$${params.value.toLocaleString()}`;
      }
    },
    { field: 'contact', headerName: 'Contact', width: 200,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          {params.value}
          <IconButton size="small" onClick={() => handleEmailClick(params.value)}>
            <EmailIcon />
          </IconButton>
        </Box>
      )
    },
    { field: 'priority', headerName: 'Priority', width: 100 },
    { field: 'comments', headerName: 'Comments', flex: 1 },
  ];

  return (
    <Grid container spacing={3}>
      {/* Event Tasks Section */}
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">
              Event Tasks
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenNewTaskDialog}
            >
              Add New Task
            </Button>
          </Box>
          <DataGrid
            rows={tasks}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            checkboxSelection
            disableSelectionOnClick
            autoHeight
            components={{
              Toolbar: GridToolbar,
            }}
            sx={{
              '& .MuiDataGrid-toolbarContainer': {
                borderBottom: 'solid 1px rgba(224, 224, 224, 1)',
                padding: 1,
              },
            }}
          />
        </Paper>
      </Grid>

      {/* Vendor Requirements Section */}
      <Grid item xs={12} md={12}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" mb={2}>
            Vendor Requirements
          </Typography>
          <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
            {partiesInvolved.map(vendor => (
              <VendorRequirements
                key={vendor.name}
                vendor={{ name: vendor.name, email: vendor.contacts[0] }}
                requirements={vendorRequirements[vendor.name] || []}
                onUpdateRequirements={(updatedRequirements) => handleUpdateRequirements(vendor.name, updatedRequirements)}
              />
            ))}
          </Box>
        </Paper>
      </Grid>
      {/* Existing dialogs */}
      <Dialog open={emailDialogOpen} onClose={handleCloseEmailDialog}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to send an email to {selectedContact}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEmailDialog}>Cancel</Button>
          <Button onClick={sendEmail} variant="contained" color="primary">
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={newTaskDialogOpen} onClose={handleCloseNewTaskDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Task</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Task Details
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            name="task"
            label="Task Name"
            type="text"
            fullWidth
            value={newTask.task}
            onChange={handleNewTaskChange}
          />
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Status
          </Typography>
          <Select
            margin="dense"
            name="status"
            label="Status"
            fullWidth
            value={newTask.status}
            onChange={handleNewTaskChange}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Type
          </Typography>
          <Select
            margin="dense"
            name="type"
            label="Task Type"
            fullWidth
            value={newTask.type}
            onChange={handleNewTaskChange}
          >
            {taskTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Responsible Party
          </Typography>
          <Select
            margin="dense"
            name="party"
            label="Party Involved"
            fullWidth
            value={newTask.party}
            onChange={handleNewTaskChange}
          >
            {partiesInvolved.map((party) => (
              <MenuItem key={party.name} value={party.name}>{party.name}</MenuItem>
            ))}
          </Select>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Contact
          </Typography>
          <Select
            margin="dense"
            name="contact"
            label="Contact"
            fullWidth
            value={newTask.contact}
            onChange={handleNewTaskChange}
          >
            {partiesInvolved.find(p => p.name === newTask.party)?.contacts.map((contact) => (
              <MenuItem key={contact} value={contact}>{contact}</MenuItem>
            ))}
          </Select>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Cost
          </Typography>
          <TextField
            margin="dense"
            name="cost"
            label="Estimated Cost ($)"
            type="number"
            fullWidth
            value={newTask.cost}
            onChange={handleNewTaskChange}
          />
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Priority
          </Typography>
          <Select
            margin="dense"
            name="priority"
            label="Priority Level"
            fullWidth
            value={newTask.priority}
            onChange={handleNewTaskChange}
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            Additional Information
          </Typography>
          <TextField
            margin="dense"
            name="comments"
            label="Comments or Notes"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={newTask.comments}
            onChange={handleNewTaskChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTaskDialog}>Cancel</Button>
          <Button onClick={handleAddNewTask} variant="contained" color="primary">
            Add Task
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default TasksTable;