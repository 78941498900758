import React from 'react';
import './Card.css';
import Button from '@mui/material/Button';

const Card = ({ companyName, addonType, desc, count, seeMore, onQuickAdd }) => {
  return (
    <div className="card">
      <div className="textBlock">{companyName}</div>
      <div className="textBlock2">{addonType}</div>
      <div className="description">Worked with {count} times</div>
      <div>
        <Button onClick={(e) => { e.stopPropagation(); onQuickAdd(); }}>Quick Add</Button>
      </div>
      <div onClick={(e) => { e.stopPropagation(); seeMore(); }}>
        <Button>See More</Button>
      </div>
    </div>
  );
};

export default Card;
