import React from 'react';
import { useLocation } from 'react-router-dom';
import VenueOwnerNavBar from './VenueOwnerNavBar';
import EventHostNavBar from './EventHostNavBar';

function ConditionalNavBar({cartCount}) {
    const location = useLocation();
    console.log(location.pathname);

    // If we are in a path which is created for the host, return the host's navbar
    // If we are in a path which is created for the service provider return the provider's nav bar
    // This needs to be switched to a switch statement that returns the correct navbar based on the kind of service provider
    if (location.pathname.startsWith('/venueOwner')) {
        return <VenueOwnerNavBar />
    } else if (location.pathname === '/' || location.pathname === '/contact' || location.pathname === '/pricing') {
        return <></>;
    }
    return <EventHostNavBar cartCount={cartCount}/>;
}

export default ConditionalNavBar;
