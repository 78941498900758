import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import MessageSidebar from '../components/Messaging/MessageSidebar';
import UtteranceList from '../components/Messaging/UtteranceList';
import { events } from '../data/conversations';

function MessagesPage({ messageTags, setMessageTags }) {
  const [selectedConversation, setSelectedConversation] = useState(null);

  useEffect(() => {
    if (events.length > 0 && events[0].conversations.length > 0) {
      setSelectedConversation(events[0].conversations[0]);
    }
  }, []);

  const handleTagClick = (tagName) => {
    // Implement scrolling logic here
    console.log(`Clicked tag: ${tagName}`);
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={3}>
          <MessageSidebar
            onSelectConversation={setSelectedConversation}
            selectedConversation={selectedConversation}
          />
        </Grid>
        <Grid item xs={9}>
          {selectedConversation ? (
            <UtteranceList 
              conversation={selectedConversation} 
              onTagClick={handleTagClick}
              tags={messageTags}
              onAddTag={(tag) => setMessageTags([...messageTags, tag])}
              onRemoveTag={(tag) => setMessageTags(messageTags.filter(t => t.label !== tag.label))}
            />
          ) : (
            <Box sx={{ p: 2 }}>Select a conversation to start messaging.</Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default MessagesPage;
