import React, { useState, useRef } from "react";
import axios from "axios";
import {
  Link,
  Box,
  Paper,
  Card,
  CardContent,
  CardMedia,
  Typography,
  TextField,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { styled, keyframes } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import VenueBox from "../../Venue/VenueBox";
import AddonsGrid from "./AddonsGrid";
import SponsorGrid from "./SponsorGrid";
import AddIcon from "@mui/icons-material/Add"; // Import the Add icon
import { getBackendUserUrl } from "../../../utils";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

const HighlightedField = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  animation: `${pulseAnimation} 2s infinite`,
  transition: "all 0.3s ease-in-out",
}));

const EventOverview = ({ eventData, setEventData, onAddTag }) => {
  const [editingSections, setEditingSections] = useState({
    basics: false,
    audience: false,
    format: false,
    speakers: false,
    marketing: false,
  });

  const eventBasicsRef = useRef(null);
  const audienceInfoRef = useRef(null);
  const targetAudienceRef = useRef(null);
  const venueRef = useRef(null);
  const marketingRef = useRef(null);
  const eventFormatRef = useRef(null);
  const speakersRef = useRef(null);

  const [highlightedField, setHighlightedField] = useState(null);
  const [highlightedSection, setHighlightedSection] = useState(null);

  const handleEdit = (section) => {
    setEditingSections((prev) => ({ ...prev, [section]: true }));
  };

  const handleSave = async (section) => {
    setEditingSections((prev) => ({ ...prev, [section]: false }));

    try {
      let endpoint = "";
      let data = {};

      switch (section) {
        case "basics":
          endpoint = `/event/update_event_basics`;
          const typeArray = eventData.event.type
            ? eventData.event.type
                .split(",")
                .map((item) => item.trim())
                .filter(Boolean)
            : [];

          data = {
            eventId: eventData.event.eventId,
            title: eventData.event.title || "",
            date: eventData.event.date || "",
            eventVision: eventData.event.vision || "",
            type: typeArray,
          };
          break;

        case "audience":
          endpoint = `/event/update_audience`;
          data = {
            eventId: eventData.event.eventId,
            guestCount: eventData.event.guest_count,
            targetAudience: eventData.event.target_audience,
            demographics: {
              ageRange: eventData.event.demographics?.ageRange,
              background: eventData.event.demographics?.background,
            },
          };
          break;

        case "speakers":
          endpoint = `/event/update_speakers_and_presenters`;
          const combinedLinkedInUrls = [
            ...(eventData.event.format?.linkedinUrls || []),
            ...(eventData.event.speakers_and_presenters || []),
          ];
          data = {
            eventId: eventData.event.eventId,
            linkedinUrls: combinedLinkedInUrls,
          };
          break;

        case "format":
          endpoint = `/event/update_format`;
          data = {
            eventId: eventData.event.eventId,
            agenda: eventData.event?.format?.agenda || "",
            keyActivities: eventData.event?.format?.keyActivities?.map(item => item.trim()).filter(Boolean) || [],
            specialFeatures: eventData.event?.format?.specialFeatures?.map(item => item.trim()).filter(Boolean) || [],
          };
          break;

        case "marketing":
          endpoint = `/event/update_marketing`;
          data = {
            eventId: eventData.event.eventId,
            marketing_strategy: eventData.event.marketing_strategy || "",
            expectedMediaCoverage: eventData.event.expected_media_coverage || "",
            // channels: eventData.event.marketing_channels || [],
          };
          break;

        default:
          console.warn(`Unhandled section: ${section}`);
          return;
      }

      if (endpoint) {
        console.log("Sending request to:", getBackendUserUrl() + endpoint);
        console.log("With data:", data);

        const response = await axios.put(getBackendUserUrl() + endpoint, data);
        console.log("Response:", response.data);
      }
    } catch (error) {
      console.error("Error details:", {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        config: error.config,
      });
    }
  };

  const handleChange = (section, field, value) => {
    setEventData((prevData) => {
      const newData = { ...prevData };

      if (field.includes('.')) {
        const [parent, child] = field.split('.');
        
        // Initialize the parent object if it doesn't exist
        if (!newData.event[parent]) {
          newData.event[parent] = {};
        }

        // For array fields, store the raw string input temporarily
        if (child === 'keyActivities' || child === 'specialFeatures') {
          // Store both the raw input and the processed array
          if (!newData.event[parent]._raw) {
            newData.event[parent]._raw = {};
          }
          newData.event[parent]._raw[child] = value;
          
          // Only convert to array if there's content
          if (value.trim()) {
            newData.event[parent][child] = value.split(',')
              .map(item => item.trim())
              .filter(Boolean);
          } else {
            newData.event[parent][child] = [];
          }
        } else {
          newData.event[parent][child] = value;
        }
      } else {
        newData.event[field] = value;
      }

      return newData;
    });
  };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const renderSectionHeader = (title, section) => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <IconButton
        onClick={() =>
          editingSections[section] ? handleSave(section) : handleEdit(section)
        }
      >
        {editingSections[section] ? <SaveIcon /> : <EditIcon />}
      </IconButton>
    </Box>
  );

  console.log("EventData Overview:", eventData);
  console.log("Audience Data:", eventData.event.audienceData);
  console.log("EventData Overview:", eventData);
  // console.log('Event Overview:', event);
  console.log("Audience Data:", eventData.event.audienceData);

  const venue = safe_get(eventData, "venue", {});

  // Helper function to safely get nested values
  function safe_get(obj, ...keys) {
    let current = obj;
    for (const key of keys) {
      if (current === undefined || current === null) return undefined;
      current = current[key];
    }
    return current;
  }

  // Render text field with proper null checks
  const renderTextField = (section, field, label) => {
    const value = safe_get(eventData.event, field) || "";
    return (
      <TextField
        fullWidth
        margin="normal"
        label={label}
        value={value}
        onChange={(e) => handleChange(section, field, e.target.value)}
        disabled={!editingSections[section]}
      />
    );
  };

  // Calculate total cost with null checks
  const totalCost = (
    (eventData.event.cost || 0) +
    (eventData.event.addons || []).reduce((sum, addon) => {
      // If the addon is a sponsor, subtract its price, otherwise add it
      return addon.addonType === "Sponsor" 
        ? sum - (addon.price || 0)
        : sum + (addon.price || 0);
    }, 0)
  ).toLocaleString();

  // Format dates with null checks
  const startDate = eventData.event.start_date
    ? new Date(eventData.event.start_date).toLocaleDateString()
    : "TBD";
  const endDate = eventData.event.end_date
    ? new Date(eventData.event.end_date).toLocaleDateString()
    : "TBD";

  const handleTagClick = (tagName) => {
    console.log("Tag clicked:", tagName);
    
    if (!tagName) {
      console.warn('No tag name provided for click handler');
      return;
    }

    // Use the parent component's navigation handler
    onAddTag(tagName);

    let ref = null;
    let section = null;

    switch (tagName.toString().toLowerCase()) {  // Add toString() for safety
      case 'event basics':
      case 'date and time':
      case 'event vision':
      case 'event type':
        ref = eventBasicsRef;
        section = 'basics';
        break;

      case 'target audience':
      case 'audience information':
      case 'demographics':
        ref = audienceInfoRef;
        section = 'audience';
        break;

      case 'venue':
      case 'location':
        ref = venueRef;
        section = 'venue';
        break;

      case 'event format':
      case 'agenda overview':
        ref = eventFormatRef;
        section = 'format';
        break;

      case 'speakers':
      case 'speakers and presenters':
        ref = speakersRef;
        section = 'speakers';
        break;

      case 'marketing and promotion':
      case 'marketing strategy':
        ref = marketingRef;
        section = 'marketing';
        break;

      default:
        break;
    }

    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Add highlight effect
      ref.current.style.backgroundColor = 'rgba(25, 118, 210, 0.1)';
      setTimeout(() => {
        ref.current.style.backgroundColor = '';
        setHighlightedField(null);
        setHighlightedSection(null);
      }, 3000);
    }

    if (section) {
      setHighlightedSection(section);
    }
  };

  return (
    <Grid container spacing={3}>
      {/* Venue Details */}
      <Grid item xs={12} md={6} id="venue-section">
        <VenueBox
          venueData={eventData.venue}
          highlightedSection={highlightedSection}
          venueRef={venueRef}
        />
      </Grid>

      {/* Event Basics */}
      <Grid item xs={12} md={6} id="Event-Basics-section">
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          {renderSectionHeader("Event Basics", "basics")}
          <TextField
            fullWidth
            margin="normal"
            label="Event Name"
            value={eventData.event.title || ""}
            onChange={(e) => handleChange("event", "title", e.target.value)}
            disabled={!editingSections.basics}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <DateTimePicker
                label="Start Date & Time"
                value={eventData.event.start_date ? new Date(eventData.event.start_date) : null}
                onChange={(newValue) => {
                  handleChange("event", "start_date", newValue?.toISOString());
                }}
                disabled={!editingSections.basics}
                sx={{ flex: 1 }}
              />
              <DateTimePicker
                label="End Date & Time"
                value={eventData.event.end_date ? new Date(eventData.event.end_date) : null}
                onChange={(newValue) => {
                  handleChange("event", "end_date", newValue?.toISOString());
                }}
                disabled={!editingSections.basics}
                sx={{ flex: 1 }}
              />
            </Box>
          </LocalizationProvider>
          <TextField
            fullWidth
            margin="normal"
            label="Duration"
            value={eventData.event.duration}
            onChange={(e) => handleChange("event", "duration", e.target.value)}
            disabled={!editingSections.basics}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Vision/Purpose"
            value={eventData.event.vision || "Not specified"}
            onChange={(e) => handleChange("event", "vision", e.target.value)}
            disabled={!editingSections.basics}
            multiline
            rows={2}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Event Type"
            value={eventData.event.type}
            onChange={(e) => handleChange("event", "type", e.target.value)}
            disabled={!editingSections.basics}
          />
        </Paper>
      </Grid>

      {/* Add-ons Section */}
      <AddonsGrid eventDetails={eventData} onTagClick={handleTagClick} />

      {/* Audience Information */}
      <Grid item xs={12} md={6}>
        <Paper id="audience-section" ref={targetAudienceRef}elevation={3} sx={{ p: 3, mb: 3 }}>
          {renderSectionHeader("Audience Information", "audience")}
          <Grid
            container
            spacing={3}
            sx={{
              transition: "all 0.3s ease-in-out",
              ...(highlightedSection === "audience" && {
                backgroundColor: "rgba(25, 118, 210, 0.1)",
                borderRadius: "4px",
              }),
            }}
          >
            <Grid ref={audienceInfoRef} item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                label="Target Audience"
                value={eventData.event.target_audience || ''}
                onChange={(e) => handleChange("event", "target_audience", e.target.value)}
                disabled={!editingSections.audience}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Expected Attendees"
                type="number"
                value={eventData.event.guest_count || ''}
                onChange={(e) => handleChange("event", "guest_count", parseInt(e.target.value) || 0)}
                disabled={!editingSections.audience}
                InputProps={{
                  inputProps: { min: 0 }  // Prevents negative numbers
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Age Range"
                value={eventData.event.demographics?.ageRange}
                onChange={(e) =>
                  handleChange("audience", "demographics", {
                    ...eventData.event.demographics,
                    ageRange: e.target.value,
                  })
                }
                disabled={!editingSections.audience}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Professional Background"
                value={eventData.event.demographics?.background}
                onChange={(e) =>
                  handleChange("audience", "demographics", {
                    ...eventData.event.demographics,
                    background: e.target.value,
                  })
                }
                disabled={!editingSections.audience}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Audience Breakdown
              </Typography>
              <Box sx={{ width: "100%", height: 300 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={eventData.event.audience_data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {(eventData.event.audience_data || []).map(
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Sponsors Section */}
      <SponsorGrid eventDetails={eventData} />

      {/* Event Format */}
      <Grid item xs={12} md={6}>
        <Paper ref={eventFormatRef} elevation={3} sx={{ p: 3, mb: 3 }}>
          {renderSectionHeader("Event Format", "format")}

          {/* Agenda Overview */}
          <TextField
            fullWidth
            margin="normal"
            label="Agenda Overview"
            value={eventData.event?.format?.agenda || ""}
            onChange={(e) => handleChange("event", "format.agenda", e.target.value)}
            disabled={!editingSections.format}
            multiline
            rows={2}
          />

          {/* Key Activities */}
          <TextField
            fullWidth
            margin="normal"
            label="Key Activities"
            value={eventData.event?.format?._raw?.keyActivities || eventData.event?.format?.keyActivities?.join(", ") || ""}
            onChange={(e) => handleChange("event", "format.keyActivities", e.target.value)}
            disabled={!editingSections.format}
            multiline
            rows={2}
            helperText="Separate activities with commas"
          />

          {/* Special Features */}
          <TextField
            fullWidth
            margin="normal"
            label="Special Features"
            value={eventData.event?.format?._raw?.specialFeatures || eventData.event?.format?.specialFeatures?.join(", ") || ""}
            onChange={(e) => handleChange("event", "format.specialFeatures", e.target.value)}
            disabled={!editingSections.format}
            multiline
            rows={2}
            helperText="Separate features with commas"
          />
        </Paper>
      </Grid>

      {/* Speakers and Presenters */}
      <Grid item xs={12} md={6}>
        <Paper ref={speakersRef} elevation={3} sx={{ p: 3, mb: 3 }}>
          {renderSectionHeader("Speakers and Presenters", "speakers")}

          {/* Button to add LinkedIn URL */}
          {editingSections.speakers && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={() =>
                  setEventData((prev) => ({
                    ...prev,
                    event: {
                      ...prev.event,
                      format: {
                        ...prev.event.format,
                        linkedinUrls: [
                          "", // Add an empty URL string to the beginning of the array
                          ...(prev.event.format?.linkedinUrls || []), // Spread the existing URLs after the new one
                        ],
                      },
                    },
                  }))
                }
              >
                <AddIcon />
              </IconButton>

              <Typography variant="subtitle1">Add LinkedIn URL</Typography>
            </Box>
          )}

          {/* List of LinkedIn URLs */}
          {[
            ...(eventData.event.format?.linkedinUrls || []), // New LinkedIn URLs from the state
            ...(eventData.event.speakers_and_presenters || []), // Existing URLs from speakers_and_presenters
          ].map((url, index) => (
            <Box
              key={index}
              sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}
            >
              <TextField
                fullWidth
                label="LinkedIn URL"
                value={url}
                onChange={(e) => {
                  const newLinkedInUrls = [
                    ...eventData.event.format.linkedinUrls,
                  ];
                  newLinkedInUrls[index] = e.target.value;
                  setEventData((prev) => ({
                    ...prev,
                    event: {
                      ...prev.event,
                      format: {
                        ...prev.event.format,
                        linkedinUrls: newLinkedInUrls,
                      },
                    },
                  }));
                }}
                error={
                  url && !/^https:\/\/(www\.)?linkedin\.com\/.*$/.test(url)
                }
                helperText={
                  url && !/^https:\/\/(www\.)?linkedin\.com\/.*$/.test(url)
                    ? "Please enter a valid LinkedIn URL."
                    : ""
                }
                disabled={!editingSections.speakers} // Only disable if not in edit mode
              />
              {/* Conditionally render the Delete button */}
              {editingSections.speakers && (
                <IconButton
                  onClick={() => {
                    const isBackendUrl =
                      eventData.event.speakers_and_presenters?.includes(url);

                    if (isBackendUrl) {
                      // Remove URL from speakers_and_presenters in the frontend state (UI only)
                      const newSpeakersAndPresenters =
                        eventData.event.speakers_and_presenters.filter(
                          (item) => item !== url
                        );
                      setEventData((prev) => ({
                        ...prev,
                        event: {
                          ...prev.event,
                          speakers_and_presenters: newSpeakersAndPresenters,
                        },
                      }));
                    } else {
                      // Remove URL from format.linkedinUrls (UI only)
                      const newLinkedInUrls =
                        eventData.event.format.linkedinUrls.filter(
                          (item) => item !== url
                        );
                      setEventData((prev) => ({
                        ...prev,
                        event: {
                          ...prev.event,
                          format: {
                            ...prev.event.format,
                            linkedinUrls: newLinkedInUrls,
                          },
                        },
                      }));
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
        </Paper>
      </Grid>

      {/* Marketing and Promotion */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }} ref={marketingRef}>
          {renderSectionHeader("Marketing and Promotion", "marketing")}
          <Box
            sx={{
              transition: "all 0.3s ease-in-out",
              ...(highlightedSection === "marketing" && {
                backgroundColor: "rgba(25, 118, 210, 0.1)",
                borderRadius: "4px",
                padding: "8px",
              }),
            }}
          >
            <Typography variant="subtitle1" gutterBottom>
              Marketing Channels
            </Typography>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
            {eventData.event.marketing?.channels.map((channel, index) => (
              <Chip key={index} label={channel} onDelete={editingSections.marketing ? () => {} : undefined} />
            ))}
          </Box> */}
            <TextField
              fullWidth
              margin="normal"
              label="Promotional Strategy"
              value={eventData.event.marketing_strategy || ""}
              onChange={(e) =>
                handleChange("event", "marketing_strategy", e.target.value)
              }
              disabled={!editingSections.marketing}
              multiline
              rows={2}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Expected Media Coverage"
              value={eventData.event.expected_media_coverage || ""}
              onChange={(e) =>
                handleChange("event", "expected_media_coverage", e.target.value)
              }
              disabled={!editingSections.marketing}
              multiline
              rows={2}
            />
          </Box>
        </Paper>
      </Grid>

      {/* Total Cost Section */}
      <Grid item xs={12} md={4}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Total Costs Breakdown
          </Typography>
          {/* Venue Cost */}
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="body1">Venue:</Typography>
            <Typography variant="body1">
              ${eventData.event.cost.toLocaleString()}
            </Typography>
          </Box>
          
          {/* Addons and Sponsors */}
          {eventData.event.addons.map((addon, index) => (
            <Box 
              key={index} 
              sx={{ 
                pl: 2, 
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}
            >
              <Box>
                <Typography variant="body2" display="block" gutterBottom>
                  {addon.addonType}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {addon.company?.name}
                </Typography>
              </Box>
              <Typography 
                variant="body2" 
                sx={{ 
                  ml: 2,
                  color: addon.addonType?.toLowerCase().includes('sponsor') ? 'success.main' : 'text.primary',
                  fontWeight: addon.addonType?.toLowerCase().includes('sponsor') ? 'bold' : 'normal'
                }}
              >
                {addon.addonType?.toLowerCase().includes('sponsor') ? '-' : ''}
                ${addon.price.toLocaleString()}
              </Typography>
            </Box>
          ))}
          
          {/* Total Cost */}
          <Box 
            display="flex" 
            justifyContent="space-between" 
            mt={2}
            pt={2}
            sx={{ borderTop: 1, borderColor: 'divider' }}
          >
            <Typography variant="h6">Total Cost After Sponsorships</Typography>
            <Typography variant="h6" sx={{ ml: 2 }}>
              ${totalCost.toLocaleString()}
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default EventOverview;
