import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import logo from '../../assets/happeings_logo.png';
import '../../styles/landing.css';
import EmailIcon from '@mui/icons-material/Email';

const Contact = () => {
    const [activeDropdown, setActiveDropdown] = useState(null);

    const solutionsItem = { label: 'Solutions' };
    const navItems = [
        { label: 'Pricing', path: '/pricing' },
        { label: 'Contact', path: '/contact' }
    ];

    const navItemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
    };

    const handleMouseOver = (index) => setActiveDropdown(index);
    const handleMouseOut = () => setActiveDropdown(null);

    return (
        <div className="landing-page">
            <nav className="nav">
                <motion.div
                    variants={navItemVariants}
                    initial="hidden"
                    animate="visible"
                    className="logo"
                    style={{ marginLeft: '8%' }}
                >
                    <Link to="/" className="navbar-brand">
                        <img 
                            src={logo} 
                            alt="Logo" 
                            style={{ 
                                height: '70px', 
                                width: 'auto',
                                marginRight: '10px'
                            }} 
                        />
                    </Link>
                </motion.div>

                <div className="nav-items">
                    <motion.div
                        key="solutions"
                        variants={navItemVariants}
                        initial="hidden"
                        animate="visible"
                        className="nav-item"
                        onMouseOver={() => handleMouseOver('solutions')}
                        onMouseOut={handleMouseOut}
                    >
                        <Link
                            to="#"
                            className="nav-button"
                        >
                            {solutionsItem.label}
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{
                                    rotateX: activeDropdown === 'solutions' ? 180 : 0,
                                }}
                                transition={{ duration: 0.2 }}
                                style={{
                                    marginLeft: '0.5rem',
                                    transformOrigin: 'center',
                                }}
                            >
                                <ChevronDown className="w-3 h-3" />
                            </motion.div>
                        </Link>
                    </motion.div>

                    {navItems.map((item, index) => (
                        <motion.div
                            key={index}
                            variants={navItemVariants}
                            initial="hidden"
                            animate="visible"
                            className="nav-item"
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                        >
                            <Link
                                to={item.path}
                                className="nav-button"
                            >
                                {item.label}
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </nav>

            <div className="main-content" style={{ marginTop: '100px' }}>
                <div className="hero-content">
                    <h1 className="hero-title" style={{ color: 'white' }}>Hello</h1>
                    <p className="contact-hero-description" style={{ fontSize: '1rem', color: 'white' }}>
                        Please <a href="/" className="contact-professional-link" style={{ color: '#2196f3', textDecoration: 'underline' }}>sign up for our beta</a> to get access to our platform. If you want to partner with us directly
                        please contact us at <a href="mailto:eventrent.contact@gmail.com" className="contact-professional-link" style={{ color: '#2196f3', textDecoration: 'underline', display: 'inline-flex', alignItems: 'center', gap: '4px' }}>
                        eventrent.contact@gmail.com
                            <EmailIcon fontSize="small" />
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Contact;