import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Chip, Tooltip, Card, CardContent, Menu, MenuItem, Grid } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import { blue, green, orange, purple, red } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';

const partyColors = {
  Catering: blue[500],
  Photography: green[500],
  Venue: orange[500],
  Cleaning: purple[500],
  Other: red[500],
};

// Add this function to combine and format all possible parties
const getAllParties = (eventDetails) => {
  if (!eventDetails) return [];
  const parties = [];
  // Add addons
  if (Array.isArray(eventDetails.event.addons)) {
    eventDetails.event.addons.forEach(addon => {
      parties.push({
        partyType: addon.addonType,
        partyId: addon.eventAddonsId,
        companyId: addon.companyId,
        companyName: addon.companyName,
        imageUrl: addon.logoUrl
      });
    });
  }
  
  return parties;
};

const EventTimeline = ({ eventDetails, events, onAddEvent, onEditEvent, onDeleteEvent, onSyncGoogleCalendar }) => {
  console.log("EventTimeline Props:", {
    eventDetails,
    events
    });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editingEvent, setEditingEvent] = useState({
    time: '',
    title: '',
    desc: '',
    parties: []
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Party Management Handlers
  const handleAddPartyClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddPartyClose = () => {
    setAnchorEl(null);
  };

  const handleAddParty = async (party) => {
    if (selectedEvent) {
      const updatedEvent = {
        ...selectedEvent,
        parties: [
          ...(selectedEvent.parties || []),
          {
            partyType: party.partyType,
            partyId: party.partyId,
            companyId: party.companyId,
            companyName: party.companyName
          }
        ]
      };
      await onEditEvent(updatedEvent);
      handleAddPartyClose();
      setSelectedEvent(null);
    }
  };

  const handleRemoveParty = (indexToRemove) => {
    setEditingEvent(prev => ({
      ...prev,
      parties: prev.parties.filter((_, index) => index !== indexToRemove)
    }));
  };

  // Dialog Management Handlers
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditingEvent({
      time: '',
      title: '',
      desc: '',
      parties: []
    });
  };

  const handleEditClick = (event) => {
    setEditingEvent({
      eventTimelineId: event.eventTimelineId,
      time: event.time,
      title: event.title,
      desc: event.desc,
      parties: event.parties || []
    });
    setIsDialogOpen(true);
  };

  const handleSaveEvent = async () => {
    try {
      const formattedEvent = {
        eventId: eventDetails.event.id,
        time: editingEvent.time,
        title: editingEvent.title,
        desc: editingEvent.desc || '',
        parties: editingEvent.parties.map(party => ({
          partyType: party.partyType,
          partyId: party.id || party.partyId,
          companyName: party.companyName,
          eventTimelinePartyId: party.eventTimelinePartyId,
          created_at: party.created_at
        }))
      };

      if (editingEvent.eventTimelineId) {
        await onEditEvent({
          ...formattedEvent,
          eventTimelineId: editingEvent.eventTimelineId
        });
      } else {
        await onAddEvent(formattedEvent);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const handleDeleteClick = (eventTimelineId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      onDeleteEvent(eventTimelineId);
    }
  };

  const handleDeleteParty = async (timelineEvent, party) => {
    const updatedEvent = {
      ...timelineEvent,
      parties: timelineEvent.parties.filter(p => 
        p.eventTimelinePartyId !== party.eventTimelinePartyId
      )
    };
    await onEditEvent(updatedEvent);
  };

  const handleTimelineEventClick = (event, e) => {
    e.stopPropagation();
    setSelectedEvent(event);
    setAnchorEl(e.currentTarget);
  };

  // Update the handleAddParty function for the dialog
  const handleAddPartyInDialog = (party) => {
    setEditingEvent(prev => ({
      ...prev,
      parties: [
        ...prev.parties,
        {
          partyType: party.partyType,
          id: party.id,
          companyName: party.companyName,
          created_at: new Date().toISOString()
        }
      ]
    }));
    handleAddPartyClose();
  };

  // Add this grouping logic before the return statement
  const groupedEvents = (events || []).reduce((acc, event) => {
    const time = event.time || '00:00';
    if (!acc[time]) {
      acc[time] = [];
    }
    acc[time].push(event);
    return acc;
  }, {});

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', position: 'relative' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<SyncIcon />}
          onClick={onSyncGoogleCalendar}
          sx={{ mb: 1, bgcolor: blue[700], '&:hover': { bgcolor: blue[800] } }}
        >
          Sync with Google Calendar
        </Button>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          sx={{ bgcolor: blue[500], '&:hover': { bgcolor: blue[700] } }}
        >
          Add Event
        </Button>
      </Box>
      <Timeline sx={{ mb: 4, pl: 0, '& .MuiTimelineItem-root::before': { flex: 0 } }}>
        {Object.entries(groupedEvents)
          .sort(([timeA], [timeB]) => timeA.localeCompare(timeB))
          .map(([time, timeEvents]) => (
            <TimelineItem key={`timeline-${time}`}>
              <TimelineSeparator>
                <TimelineDot sx={{ bgcolor: blue[700] }} />
                <TimelineConnector sx={{ bgcolor: blue[700] }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="subtitle1" sx={{ color: blue[700], fontWeight: 'bold' }}>
                  {time}
                </Typography>
                {timeEvents.map((event) => (
                  <Card key={`event-${event.eventTimelineId}`} sx={{ mt: 2, mb: 2 }}>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="h6">{event.title}</Typography>
                          <Typography variant="body2" sx={{ mt: 0.5, mb: 1 }}>{event.desc}</Typography>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center' }}>
                            {event.parties?.map((party) => (
                              <Box 
                                key={`party-${party.eventTimelinePartyId || uuidv4()}`} 
                                sx={{ display: 'inline-flex', mr: 1, mb: 1 }}
                              >
                                <Chip 
                                  label={getPartyDisplayName(party, eventDetails)}
                                  size="small" 
                                  onDelete={() => handleDeleteParty(event, party)}
                                  deleteIcon={
                                    <CloseIcon 
                                      fontSize="small"
                                      sx={{ 
                                        color: 'white',
                                        '&:hover': { 
                                          color: 'rgba(255, 255, 255, 0.7)' 
                                        } 
                                      }}
                                    />
                                  }
                                  sx={{ 
                                    bgcolor: partyColors[party.partyType] || partyColors.Other, 
                                    color: 'white',
                                    '& .MuiChip-deleteIcon': {
                                      color: 'white',
                                      '&:hover': { 
                                        color: 'rgba(255, 255, 255, 0.7)' 
                                      }
                                    }
                                  }} 
                                />
                              </Box>
                            ))}
                            <Tooltip title="Add party">
                              <IconButton
                                onClick={(e) => handleTimelineEventClick(event, e)}
                                size="small"
                                sx={{ ml: 1 }}
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box>
                          <IconButton size="small" onClick={() => handleEditClick(event)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton size="small" onClick={() => handleDeleteClick(event.eventTimelineId)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleAddPartyClose}
        PaperProps={{
          sx: {
            maxHeight: 300,
            width: '300px'
          }
        }}
      >
        {getAllParties(eventDetails)?.length > 0 ? (
          getAllParties(eventDetails).map((party) => (
            <MenuItem 
              key={party.partyId} 
              onClick={() => {
                if (isDialogOpen) {
                  handleAddPartyInDialog(party);
                } else {
                  handleAddParty(party);
                }
              }}
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 2,
                py: 1
              }}
            >
              {party.imageUrl && (
                <Box
                  component="img"
                  src={party.imageUrl}
                  alt={party.companyName}
                  sx={{ 
                    width: 30, 
                    height: 30, 
                    objectFit: 'contain',
                    borderRadius: '4px'
                  }}
                />
              )}
              <Box>
                <Typography variant="subtitle1">
                  {party.companyName}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {party.partyType}
                </Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            <Typography variant="body2">No parties available</Typography>
          </MenuItem>
        )}
      </Menu>
      <Dialog 
        open={isDialogOpen} 
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingEvent?.eventTimelineId ? 'Edit Event' : 'Add New Event'}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="time"
                label="Time"
                type="time"
                value={editingEvent?.time || ''}
                onChange={(e) => setEditingEvent({ ...editingEvent, time: e.target.value })}
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Title"
                value={editingEvent?.title || ''}
                onChange={(e) => setEditingEvent({ ...editingEvent, title: e.target.value })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="desc"
                label="Description"
                value={editingEvent?.desc || ''}
                onChange={(e) => setEditingEvent({ ...editingEvent, desc: e.target.value })}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Assigned Parties
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                {editingEvent.parties.map((party, index) => (
                  <Chip
                    key={index}
                    label={getPartyDisplayName(party, eventDetails)}
                    onDelete={() => handleRemoveParty(index)}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Box>
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddPartyClick}
                variant="outlined"
                size="small"
              >
                Add Party
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button 
            onClick={handleSaveEvent}
            variant="contained"
            disabled={!editingEvent.time || !editingEvent.title}
          >
            {editingEvent?.eventTimelineId ? 'Save Changes' : 'Add Event'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Add this helper function to get the party display name
const getPartyDisplayName = (party, eventDetails) => {
  // First check sponsors
  const sponsor = eventDetails?.sponsors?.find(s => s.name === party.companyName);
  if (sponsor) {
    return `${sponsor.name} (Sponsor)`;
  }

  // Then check addons
  const addon = eventDetails?.addons?.find(a => a.id === party.partyId);
  if (addon) {
    return `${addon.companyName} (${party.partyType})`;
  }

  // Fallback to just showing what we have
  return party.companyName ? `${party.companyName} (${party.partyType})` : party.partyType;
};

export default EventTimeline;