import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Box, Stepper, Step, StepLabel, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ComingSoonPopup from '../../components/ComingSoon/ComingSoonPopup';


const HostYourVenueWithUs = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const history = useHistory();

  const { showComingSoon } = ComingSoonPopup({ 
    message: "Onboard your venue! 🎉" // optional
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    handleNext();
  };

  const handleBackToOptions = () => {
    setShowForm(false);
  };

  const handleGetStarted = () => {
    // history.push('/venueOwner/addVenue');
    showComingSoon();
  };

  return (
    <>
      <Grid container sx={{ height: 'calc(100vh - 64px)' }}>
        {/* Left Side Content */}
        <Grid item xs={12} md={6} sx={{ backgroundColor: '#1976d2', color: '#fff', padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h3" gutterBottom>
            Host your venue with us.
          </Typography>
          <Box sx={{ marginY: 4 }}>
            <Card sx={{ marginBottom: 2, backgroundColor: 'transparent', color: '#fff', boxShadow: 'none' }}>
              <CardContent>
                <img
                  src="https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18:28:05.840Z"
                  alt="EventRent"
                  style={{ maxWidth: '100%', maxHeight: '700px', alignItems: 'center', transform: 'translateY(10px)', transition: 'transform 0.5s ease-in-out' }}
                  onMouseOver={(e) => (e.currentTarget.style.transform = 'translateY(0px)')}
                  onMouseOut={(e) => (e.currentTarget.style.transform = 'translateY(10px)')}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {!showForm ? (
            <Box textAlign="center" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant="h4" gutterBottom>
                Let’s plan your first event 🚀
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: 4 }}>
                We promise it’ll only take a few moments to create. Get started below.
              </Typography>
              <Button
                variant="outlined"
                onClick={handleGetStarted}
                sx={{ padding: 2, width: 250, height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <Typography variant="h6" sx={{ marginBottom: 1, textAlign: 'center' }}>Get Started with Adding Your Venue</Typography>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Begin the process of adding your venue with us!
                </Typography>
              </Button>
            </Box>
          ) : (
            <>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default HostYourVenueWithUs;
