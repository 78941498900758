import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Avatar, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginCard from './LoginCard';
import { useHistory } from 'react-router-dom';

const HamburgerMenuWithAvatar = ({ onUserLogin, onUserLogout, userData }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [showLoginCard, setShowLoginCard] = useState(false);
    const containerRef = useRef(null);
    const history = useHistory();

    const handleMenuClick = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
        setActiveItem(null);
        setShowLoginCard(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleMenuClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMenuItemClick = (item) => {
        if (item === 'logout') {
            handleLogout();
            return;
        }
        if (item === 'account') {
            history.push('/profile');
            handleMenuClose();
            return;
        }
        setActiveItem(item);
        setShowLoginCard(true);
        setMenuAnchorEl(null); // Close the menu
    };

    const handleLogout = () => {
        // Clear user data and update state
        onUserLogout(); // Notify parent component or global state about logout
        window.location.reload(); // Refresh page to clear state
    };

    const handleLoginCardClose = () => {
        setShowLoginCard(false);
        setMenuAnchorEl(null); // Ensure the menu remains closed
    };

    return (
        <div
            ref={containerRef}
            style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                borderRadius: '30px',
                padding: '4px',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                position: 'relative',
            }}
            onClick={handleMenuClick}
        >
            <IconButton
                color="primary"
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                sx={{ marginRight: '4px', width: 32, height: 32 }}
            >
                <MenuIcon sx={{ fontSize: 20 }} />
            </IconButton>

            <IconButton sx={{ width: 32, height: 32 }}>
                {userData ? (
                    <Avatar
                        alt="Profile Picture"
                        sx={{ width: 32, height: 32, fontSize: 14 }}
                        src={userData.picture}
                    />
                ) : (
                    <Avatar
                        alt="Profile Picture"
                        sx={{ width: 32, height: 32, fontSize: 14 }}
                    />
                )}
            </IconButton>

            <Menu
                id="dropdown-menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                onClick={(e) => e.stopPropagation()}
            >
                {userData ? (
                    <>
                        <MenuItem onClick={() => handleMenuItemClick('account')}>
                            Account
                        </MenuItem>
                        <MenuItem onClick={() => handleMenuItemClick('logout')}>
                            Log out
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem
                            onClick={() => handleMenuItemClick('signup')}
                            sx={{
                                backgroundColor: activeItem === 'signup' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                            }}
                        >
                            Sign up
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleMenuItemClick('login')}
                            sx={{
                                backgroundColor: activeItem === 'login' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                            }}
                        >
                            Log in
                        </MenuItem>
                    </>
                )}
            </Menu>

            {!userData && showLoginCard && 
                <Box
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        right: 0,
                        mt: 2,
                        zIndex: 1300,
                    }}
                >
                    <LoginCard onClose={handleLoginCardClose} onUserLogin={onUserLogin} /> {/* Pass close handler */}
                </Box>
            }
        </div>
    );
};

export default HamburgerMenuWithAvatar;
