import React, { useState } from 'react';
import Check from '@mui/icons-material/Check';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import '../../styles/Pricing.css';
import logo from '../../assets/happeings_logo.png'

const Pricing = () => {
    const [billingCycle, setBillingCycle] = useState('monthly');
    const [activeDropdown, setActiveDropdown] = useState(null);

    const solutionsItem = { label: 'Solutions' };
    const navItems = [
        { label: 'Pricing', path: '/pricing' },
        { label: 'Contact', path: '/contact' }
    ];

    const navItemVariants = {
        hidden: { opacity: 0, y: -20 },
        visible: { opacity: 1, y: 0 }
    };

    const handleMouseOver = (index) => setActiveDropdown(index);
    const handleMouseOut = () => setActiveDropdown(null);

    const pricingOptions = [
        {
            title: 'Basic',
            monthlyPrice: 10,
            annualPrice: 108,
            description: 'Perfect for small teams and startups',
            features: [
                'Up to 1 team members',
                'Basic analytics',
                'Up to 5 vendors',
            ],
            mostPopular: false,
        },
        {
            title: 'Pro',
            monthlyPrice: 99,
            annualPrice: 1070,
            description: 'Designed for growing businesses',
            features: [
                'Up to 5 team members',
                'Advanced analytics',
                'Knowledge graph',
                'Up to 10 vendors',
                'Custom integrations',
            ],
            mostPopular: true,
        },
        {
            title: 'Enterprise',
            monthlyPrice: 'Custom',
            annualPrice: 'Custom',
            description: 'Tailored solutions for large organizations',
            features: [
                'Unlimited team members',
                'Advanced analytics',
                'Knowledge graph',
                '100+ vendors',
                '24/7 dedicated support',
                'Advanced security',
                'Dedicated account manager',
            ],
            mostPopular: false,
        },
    ];

    return (
        <div className="pricing-page">
            <nav className="nav">
                <motion.div
                    variants={navItemVariants}
                    initial="hidden"
                    animate="visible"
                    className="logo"
                    style={{ marginLeft: '8%' }}
                >
                    <Link to="/" className="navbar-brand">
                        <img 
                            src={logo} 
                            alt="Logo" 
                            style={{ 
                                height: '70px', 
                                width: 'auto',
                                marginRight: '10px'
                            }} 
                        />
                    </Link>
                </motion.div>

                <div className="nav-items">
                    <motion.div
                        key="solutions"
                        variants={navItemVariants}
                        initial="hidden"
                        animate="visible"
                        className="nav-item"
                        onMouseOver={() => handleMouseOver('solutions')}
                        onMouseOut={handleMouseOut}
                    >
                        <Link
                            to="#"
                            className="nav-button"
                        >
                            {solutionsItem.label}
                            <motion.div
                                initial={{ rotateX: 0 }}
                                animate={{
                                    rotateX: activeDropdown === 'solutions' ? 180 : 0,
                                }}
                                transition={{ duration: 0.2 }}
                                style={{
                                    marginLeft: '0.5rem',
                                    transformOrigin: 'center',
                                }}
                            >
                                <ChevronDown className="w-3 h-3" />
                            </motion.div>
                        </Link>
                    </motion.div>

                    {navItems.map((item, index) => (
                        <motion.div
                            key={index}
                            variants={navItemVariants}
                            initial="hidden"
                            animate="visible"
                            className="nav-item"
                            onMouseOver={() => handleMouseOver(index)}
                            onMouseOut={handleMouseOut}
                        >
                            <Link
                                to={item.path}
                                className="nav-button"
                            >
                                {item.label}
                            </Link>
                        </motion.div>
                    ))}
                </div>
            </nav>
            
            <div className="pricing-container" style={{ marginTop: '200px' }}>
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    className="pricing-title"
                >
                    Flexible Pricing for Every Team
                </motion.h1>
                
                <div className="billing-toggle">
                    <button 
                        className={billingCycle === 'monthly' ? 'active' : ''}
                        onClick={() => setBillingCycle('monthly')}
                    >
                        Monthly
                    </button>
                    <button 
                        className={billingCycle === 'annual' ? 'active' : ''}
                        onClick={() => setBillingCycle('annual')}
                    >
                        Annual <span className="discount-badge">Save 10%</span>
                    </button>
                </div>

                <div className="pricing-grid">
                    {pricingOptions.map((plan, index) => (
                        <motion.div
                            key={index}
                            className={`pricing-card ${plan.mostPopular ? 'most-popular' : ''}`}
                            whileHover={{ scale: 1.03 }}
                            transition={{ duration: 0.3 }}
                        >
                            {plan.mostPopular && (
                                <div className="most-popular-badge">Most Popular</div>
                            )}
                            <h2>{plan.title}</h2>
                            <p className="plan-description">{plan.description}</p>
                            <div className="pricing">
                                <span className="price">
                                    {plan.monthlyPrice === 'Custom' 
                                        ? 'Custom Pricing' 
                                        : `$${billingCycle === 'monthly' 
                                            ? plan.monthlyPrice 
                                            : plan.annualPrice}`}
                                </span>
                                <span className="billing-period">
                                    {plan.monthlyPrice === 'Custom' 
                                        ? '' 
                                        : `per ${billingCycle === 'monthly' ? 'month' : 'year'}`}
                                </span>
                            </div>
                            <ul className="feature-list">
                                {plan.features.map((feature, idx) => (
                                    <li key={idx}>
                                        <Check className="feature-icon" size={20} />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </motion.div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pricing;