import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/favicon-32x32.png?v=2024-05-20T19:02:10.953Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/android-chrome-512x512.png?v=2024-05-20T19:01:21.626Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661c27ce6bbd20002369bc15"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});