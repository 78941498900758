import React from 'react'
import { toast } from 'react-toastify'
import { GoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useUser } from '../contexts/UserContext'
import { getBackendUserUrl, getGoogleClientId } from 'utils'

const GoogleSignIn = () => {
    const { login } = useUser()
    const history = useHistory()

    const clientId = getGoogleClientId()

    if (!clientId) {
        console.error('Google Client ID is not configured')
        return <div>Authentication configuration error</div>
    }

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            console.log('Google login response received')

            if (!credentialResponse.credential) {
                console.error('No credential received from Google')
                toast.error('Google sign-in failed. Please try again.')
                return
            }

            const apiUrl = getBackendUserUrl() + '/user/auth/google'
            console.log('Sending request to:', apiUrl)

            const response = await axios.post(
                apiUrl,
                {
                    token: credentialResponse.credential,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            console.log('Server response received:', response.status)

            const { token, user } = response.data

            login(user, token)

            if (
                user.role &&
                (user.role.includes('user') || user.role.includes('admin'))
            ) {
                //toast.success('Welcome to Happenings Beta!');
                history.push('/myevents')
            } else {
                //toast.success('Thank you for requesting beta access. We\'ll notify you once approved.');
                history.push('/')
            }
        } catch (error) {
            console.error('Error during Google Sign-In:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
            })

            if (error.response?.status === 500) {
                toast.error('Server error. Please try again later.')
            } else if (error.response?.status === 401) {
                toast.error('Authentication failed. Please try again.')
            } else {
                toast.error(
                    `Login failed: ${error.response?.data?.detail || 'Please try again'}`
                )
            }
        }
    }

    return (
        <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={(error) => {
                console.error('Google Login Error:', error)
                toast.error('Login failed. Please try again.')
            }}
            useOneTap={false}
            theme="outline"
            size="large"
            text="signin_with"
            shape="rectangular"
        />
    )
}

export default GoogleSignIn
