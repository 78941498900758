import React, {useState} from 'react';
import './FilterBar.css'
import { TiSortAlphabetically } from 'react-icons/ti';

import { Tabs, Tab, Box } from '@mui/material';
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import LocalBarOutlinedIcon from '@mui/icons-material/LocalBarOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SailingIcon from '@mui/icons-material/Sailing';
import CoPresentIcon from '@mui/icons-material/CoPresent';

const FilterBar = ({ setFilter }) => {
    const [selectedTab, setSelectedTab] = useState('All');

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        setFilter(newValue);
    };

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', bgcolor: 'background.paper' }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="filter bar"
            >
                <Tab
                    label="All"
                    value="All"
                    icon={<TiSortAlphabetically />}
                    iconPosition="start"
                />
                <Tab
                    label="TechWeek"
                    value="TechWeek"
                    icon={<LaptopOutlinedIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Galleries"
                    value="Gallery"
                    icon={<ColorLensOutlinedIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Dinner"
                    value="Dinner"
                    icon={<RestaurantIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Boat"
                    value="Boat"
                    icon={<SailingIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Bar"
                    value="Bar"
                    icon={<LocalBarOutlinedIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Club"
                    value="Club"
                    icon={<CelebrationOutlinedIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Restaurant"
                    value="Restaurant"
                    icon={<RestaurantMenuOutlinedIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Conference"
                    value="Conference"
                    icon={<GroupsIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="EventSpace"
                    value="EventSpace"
                    icon={<ConfirmationNumberIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="Corporate"
                    value="Corporate"
                    icon={<CorporateFareIcon />}
                    iconPosition="start"
                />
                <Tab
                    label="SpeakerPanel"
                    value="SpeakerPanel"
                    icon={<CoPresentIcon />}
                    iconPosition="start"
                />
            </Tabs>
        </Box>
    );
};

export default FilterBar;
