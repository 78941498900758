import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, CardMedia, CardContent, Typography, Box, Tab, Tabs } from '@mui/material';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';

const VenueBoxMarketplace = ({ venue }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/venue/${venue.venueId}`);
  };

  return (
    <Card
      className="card"
      onClick={handleClick}
      style={{
        width: '14rem',
        margin: '10px',
        cursor: 'pointer',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        overflow: 'hidden'
      }}
    >
      <CardMedia
        component="img"
        height="150"
        image={venue.VenueImages && venue.VenueImages.length > 0 ? venue.VenueImages[0].url : 'https://via.placeholder.com/150'}
        alt={venue.title}
      />
      <CardContent>
      <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div">
            {venue.title}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              color: 'text.secondary',
              fontSize: '0.875rem', // Adjust font size to match Typography
              borderRadius: 1,
            }}
          >
            <StarHalfOutlinedIcon sx={{ color: 'text.secondary', fontSize: '1rem' }} />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 0.5 }}>
              4.95
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary">
          {venue.neighborhood}
        </Typography>
        <Typography variant="body2" component="div">
          Starts at $ {venue.startPrice}
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography variant="body2" color="text.secondary">
            Proximity: 5 miles
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Availability: High
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

VenueBoxMarketplace.propTypes = {
  venue: PropTypes.shape({
    venueId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    startPrice: PropTypes.number.isRequired,
    VenueImages: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default VenueBoxMarketplace;
