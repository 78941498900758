// MyEventsAnalytics.js

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Grid, List, ListItem, ListItemText, Paper } from '@mui/material';
import { Pie, Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarElement
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  TimeScale,
  LineElement,
  PointElement,
  CategoryScale,
  BarElement
);

const MyEventsAnalytics = ({eventDetails}) => {
    const history = useHistory();

    // const eventName = eventDetails['eventName'];
    const eventSpace = 'Lume Studios';
    const eventDate = 'August 4th, 2024';
  // Example data for each section
  const audienceData = {
    labels: ['Founders', 'Investors', 'VCs', 'Operators', 'Software Engineers', 'Product Managers'],
    datasets: [
      {
        data: [30, 40, 30, 15, 25, 10],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const schedule = [
    { startTime: '20:00', endTime: '20:30', event: 'Networking' },
    { startTime: '20:30', endTime: '21:00', event: 'Introduction' },
    { startTime: '21:00', endTime: '21:30', event: 'Round Table' },
    { startTime: '21:30', endTime: '22:30', event: 'Networking' },
    { startTime: '22:30', endTime: '23:30', event: 'Cleaning Crew' }
  ];

  const scheduleChartData = {
    labels: schedule.map((s) => s.event),
    datasets: schedule.map((s, index) => ({
      label: `${s.event} (${s.startTime} - ${s.endTime})`,
      data: [
        { x: new Date(`2024-08-04T${s.startTime}`), y: index + 1 },
        { x: new Date(`2024-08-04T${s.endTime}`), y: index + 1 },
      ],
      borderColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'][index],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'][index],
      borderWidth: 8,
    })),
  };

  const scheduleOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const event = schedule[context.dataIndex];
            return `${event.event}: ${event.startTime} - ${event.endTime}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'h:mm a',
          },
        },
        min: new Date('2024-08-04T20:00:00'), // Start at 8:00 PM
        max: new Date('2024-08-04T23:00:00'), // End at 11:00 PM
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        display: false,
      },
    },
  };

  const satisfactionChartData = {
    labels: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'],
    datasets: [
      {
        data: [40, 30, 20, 5, 5],
        backgroundColor: ['#4CAF50', '#FFEB3B', '#FFC107', '#FF5722', '#F44336'],
      },
    ],
  };

  const costBreakdown = {
    Venue: 20000,
    Catering: 5000,
    Photographer: 1000,
    VIPSeating: 500,
    Marketing: {
      InstagramAds: 1000,
      NYC_Tech_Influencers: 2000,
    },
  };

  const socialGains = {
    newsletter: 200,
    instagram: 2500,
    twitter: 500,
  };

  // const calculateTotalCost = () => {
  //   const marketingTotal = Object.values(costBreakdown.Marketing).reduce((a, b) => a + b, 0);
  //   return costBreakdown.Venue + costBreakdown.Catering + costBreakdown.Photographer + marketingTotal;
  // };

  const calculateTotalCost = () => {
    const totalCost = (eventDetails.cost 
      + eventDetails.addons.reduce((sum, addon) => sum + addon.price, 0)
      - eventDetails.sponsors.reduce((sum, sponsor) => sum + sponsor.price, 0)).toLocaleString();
    return totalCost;
  }


  const socialMediaChartData = {
    labels: ['Newsletter', 'Instagram', 'Twitter'],
    datasets: [
      {
        label: 'Gains',
        data: Object.values(socialGains),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const socialMediaOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Social Media Channels',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Gains',
        },
      },
    },
  };

  const handleClick = () => {
    history.push(`/test`);
  };

  // New Demographic Data
  const demographicData = {
    labels: ['Tech', 'Finance', 'Marketing', 'Healthcare', 'Education', 'Other'],
    datasets: [
      {
        data: [30, 25, 20, 10, 10, 5],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'],
      },
    ],
  };

  // New ROI Metrics
  const roiData = {
    labels: ['Ticket Sales', 'Sponsorships', 'Merchandise', 'Workshops'],
    datasets: [
      {
        label: 'Revenue ($)',
        data: [50000, 30000, 10000, 15000],
        backgroundColor: '#4CAF50',
      },
    ],
  };

  const roiOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Revenue Breakdown',
      },
    },
  };

  return (
    <div >
    <Box sx={{ p: 4 }}>

      <Grid container spacing={4}>
        {/* Audience Type (Pie Chart) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Audience Type Distribution (Total: 150)
              </Typography>
              <Box sx={{ height: 400 }}>
                <Pie data={audienceData} />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Gains from Social Media (Bar Chart) */}
        <Grid item xs={12} md={6}>
          <Card >
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Gains from Social Media
              </Typography>
              <Box sx={{ height: 300 }}>
                <Bar data={socialMediaChartData} options={socialMediaOptions} />
              </Box>
              <Grid container spacing={1}>
                <Grid item>
                  <EmailIcon sx={{ color: '#BB001B' }} /> Newsletter
                </Grid>
                <Grid item>
                  <InstagramIcon sx={{ color: '#E1306C' }} /> Instagram
                </Grid>
                <Grid item>
                  <TwitterIcon sx={{ color: '#1DA1F2' }} /> Twitter
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Key Metrics Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Key Metrics
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      ${(105000 / 150).toFixed(2)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Revenue per Attendee
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      85%
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Attendee Satisfaction Rate
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      30%
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      First-time Attendees
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Event Schedule (Timeline) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Event Schedule
              </Typography>
              <Line data={scheduleChartData} options={scheduleOptions} />
              <List>
                {schedule.map((item, index) => (
                  <ListItem key={index} style={{ color: scheduleChartData.datasets[index].borderColor }}>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Interactions */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Total Interactions
              </Typography>
              <Typography variant="body1">Total Attendees: 150</Typography>
              <Typography variant="body1">Average Time Spent: 3 hours</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* New ROI Metrics Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Revenue Breakdown
            </Typography>
            <Box sx={{ height: 300 }}>
              <Bar data={roiData} options={roiOptions} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              Total Revenue: ${roiData.datasets[0].data.reduce((a, b) => a + b, 0).toLocaleString()}
            </Typography>
          </Paper>
        </Grid>

        {/* Satisfaction from Event (Pie Chart) */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Satisfaction from Event
              </Typography>
              <Pie data={satisfactionChartData} />
            </CardContent>
          </Card>
        </Grid>

        {/* New Demographic Data Section */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Attendee Industry Distribution
            </Typography>
            <Box sx={{ height: 300 }}>
              <Pie data={demographicData} options={{ maintainAspectRatio: false }} />
            </Box>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              Total Attendees: {demographicData.datasets[0].data.reduce((a, b) => a + b, 0)}
            </Typography>
          </Paper>
        </Grid>

      </Grid>
    </Box>
    </div>
  );
};

export default MyEventsAnalytics;
