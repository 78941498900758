import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Tooltip,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';


const SponsorGrid = ({ eventDetails }) => {
  const [sponsors, setSponsors] = useState(eventDetails.event.addons.filter(addon => addon.addonType === "Sponsor"));
  const [isEditMode, setIsEditMode] = useState(false); // State to control edit mode
  const [isOptionsListOpen, setIsOptionsListOpen] = useState(false); // State for options list visibility
  const [searchQuery, setSearchQuery] = useState(''); // State for search query
  const [filteredOptions, setFilteredOptions] = useState(eventDetails.venue.sponsors); // State for filtered options
  console.log("SponsorGrid Venue Sponsors", eventDetails.venue.sponsors);
  console.log("SponsorGrid Event Sponsors", sponsors);
  console.log("SponsorGrid infoooo ", eventDetails);

  const handleEditSponsors = () => {
    setIsEditMode(!isEditMode); // Toggle edit mode
  };

  const handleAddSponsorClick = () => {
    setIsOptionsListOpen(!isOptionsListOpen); // Toggle options list visibility
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterOptions(query);
  };

  const filterOptions = (query) => {
    setFilteredOptions(
      filteredOptions.filter(option => option.toLowerCase().includes(query.toLowerCase()))
    );
  };

  const handleSelectOption = (sponsor) => {
    setSponsors([...sponsors, sponsor]);
    setIsOptionsListOpen(false); // Close options list after selection
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper elevation={3} sx={{ p: 2, position: 'relative' }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Sponsors
        </Typography>

        {/* Edit Button */}
        <Tooltip title="Edit all sponsors">
          <IconButton
            onClick={handleEditSponsors}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: '#000',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Grid container spacing={2}>
          {sponsors.map((sponsor, index) => (
            <Grid item xs={6} sm={4} md={3} key={index}>
              <Box position="relative">
                <Card sx={{ textAlign: 'center', height: '100%' }}>
                  <CardMedia
                    component="img"
                    height="100"
                    image={sponsor.company.logoUrl}
                    alt={sponsor.company.name}
                    sx={{ objectFit: 'contain', p: 2 }}
                  />
                  <CardContent>
                    <Typography variant="body1">{sponsor.company.name}</Typography>
                  </CardContent>

                  {/* Warning Icon */}
                  <Tooltip title="Sponsor wants more information before signing up for this event">
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: '#FFA500',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      <WarningIcon />
                    </IconButton>
                  </Tooltip>
                </Card>
              </Box>
            </Grid>
          ))}

          {/* Add New Sponsor Card */}
          {isEditMode && (
            <Grid item xs={6} sm={4} md={3}>
              <Box height="100%">
                <Card sx={{ textAlign: 'center', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IconButton
                    onClick={handleAddSponsorClick}
                    sx={{
                      borderRadius: '50%',
                      width: 80,
                      height: 80,
                      backgroundColor: '#f5f5f5',
                      '&:hover': {
                        backgroundColor: '#e0e0e0',
                      },
                    }}
                  >
                    <AddIcon sx={{ fontSize: 40, color: '#000' }} />
                  </IconButton>
                </Card>

                {/* Options List */}
                {isOptionsListOpen && (
                  <Box mt={2} sx={{ position: 'absolute', zIndex: 10, width: '100%' }}>
                    <Card sx={{ maxHeight: 300, overflowY: 'auto' }}>
                      <CardContent>
                        <TextField
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                          size="small"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            ),
                          }}
                        />
                        <List>
                          {filteredOptions.map((sponsor, idx) => (
                            <ListItem button key={sponsor.id} onClick={() => handleSelectOption(sponsor)}>
                              <Box
                                component="img"
                                src={sponsor.company.logoUrl}
                                alt={sponsor.companyName}
                                sx={{ 
                                  width: 30, 
                                  height: 30, 
                                  objectFit: 'contain',
                                  borderRadius: '4px'
                                }}
                              />
                              <ListItemText 
                                primary={sponsor.companyName}
                                secondary={`Type: ${sponsor.addonType} | Count: ${sponsor.count}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Box>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default SponsorGrid;
